import dayjs from 'dayjs'

import { useGetCodeExpirationLazyQuery, useGetStudentsLazyQuery, useLoginStudentLazyQuery } from '@app/data'
import { useTranslation } from '@app/locales'
import { showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

export const useLoginData = () => {
  const { t } = useTranslation(['error', 'login'])

  const [getStudentsLazyQuery, getStudentsLazyQueryData] = useGetStudentsLazyQuery()
  const [getCodeExpirationLazyQuery, getCodeExpirationLazyQueryData] = useGetCodeExpirationLazyQuery()
  const [loginStudentLazyQuery, loginStudentLazyQueryData] = useLoginStudentLazyQuery()

  const loading =
    loginStudentLazyQueryData.loading || getStudentsLazyQueryData.loading || getCodeExpirationLazyQueryData.loading
  const error =
    loginStudentLazyQueryData.error || getStudentsLazyQueryData.error || getCodeExpirationLazyQueryData.error

  const getAvailableStudentsByCode = async (classroomLoginCode: string, withStudents = true) => {
    try {
      const result = await getStudentsLazyQuery({
        variables: { classroomLoginCode, withStudents },
        fetchPolicy: 'network-only'
      })

      return result.data?.students.classroomLoginCode
    } catch (err) {
      logError(err, 'useLoginData', 'getAvailableStudentsByCode')

      return false
    }
  }

  const getCodeExpiration = async (classroomLoginCode: string) => {
    try {
      const { error: responseError, data } = await getCodeExpirationLazyQuery({ variables: { classroomLoginCode } })

      const isExpired = dayjs(data?.students.classroomLoginCode.expirationTime).isBefore(dayjs())

      if (responseError || isExpired) {
        showToast(t('loginQR.qrError'), { type: 'error' })
        return false
      }

      return true
    } catch (err) {
      logError(err, 'useLoginData', 'getCodeExpiration')
      showToast(t('somethingWentWrong'), { type: 'error' })

      return false
    }
  }

  const getLoginDataWithStudentId = async (classroomLoginCode: string, studentId: string) => {
    try {
      const loginData = await loginStudentLazyQuery({ variables: { classroomLoginCode, studentId } })

      return loginData.data?.loginStudent
    } catch (err) {
      logError(err, 'useLoginData', 'getLoginDataWithStudentId')
      showToast(t('somethingWentWrong'), { type: 'error' })
    }
  }

  return {
    loading,
    error,
    students: getStudentsLazyQueryData.data?.students.students,
    actions: {
      getLoginDataWithStudentId,
      getAvailableStudentsByCode,
      getCodeExpiration
    }
  }
}
