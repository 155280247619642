import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Pages } from '@app/config/router/Pages'

export const PageNotExist = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(Pages.ROOT, { replace: true })
  }, [])

  return <div className="flex h-[100vh] w-full items-center justify-center"></div>
}
