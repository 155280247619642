import classNames from 'classnames'
import { ReactNode, forwardRef } from 'react'

type Props = React.HTMLProps<HTMLInputElement> & {
  onClick?: () => void
  title?: string
  icon?: ReactNode
  isActive?: boolean
}

/**
 * @group Components
 * @category Component
 */
export const Tab = forwardRef(
  ({ className, title, onClick, icon, isActive, ...restProps }: Props, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div className={classNames('flex flex-col h-14 gap-1', className)} ref={ref} {...restProps}>
        <div
          className={classNames(
            'flex flex-row gap-1 px-2 py-[10px] text-grayNeutral-600 h-full rounded-lg items-center justify-center text-sm hover:cursor-pointer hover:bg-grayNeutral-100',
            isActive && 'font-bold'
          )}
          onClick={onClick}
        >
          {icon}
          {title}
        </div>

        <div
          className={classNames(
            'bg-primary h-[2px] transition-width duration-300 ease-in-out',
            isActive ? 'w-full' : 'w-[0px]'
          )}
        />
      </div>
    )
  }
)
