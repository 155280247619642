import classnames from 'classnames'
import { ButtonHTMLAttributes, ReactElement, SVGProps } from 'react'
import React from 'react'

import { Label } from '..'
import { Dot } from './Dot'

export type IconType = (props: SVGProps<SVGSVGElement>) => JSX.Element
type IconSideType = 'left' | 'right'
type SizeType = 'large' | 'medium' | 'small' | 'xs'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  mode?: ModeType
  icon?: ReactElement<IconType> | ReactElement<any>
  iconSide?: IconSideType
  size?: SizeType
  ref?: React.Ref<HTMLButtonElement>
  disabled?: boolean
  withDot?: boolean
}
type ModeType =
  | 'primary'
  | 'primary-light'
  | 'primary-text'
  | 'primary-link'
  | 'secondary'
  | 'secondary-text'
  | 'secondary-link'
  | 'negative'
  | 'negative-light'
  | 'negative-text'
  | 'negative-link'

const sizeToLabelStyle: Record<'icon' | 'default', Record<SizeType, React.ComponentProps<'p'>['className']>> = {
  default: {
    xs: 'text-[13px] leading-4 px-3 py-[6px]',
    small: 'text-sm px-3 py-[6px]',
    medium: 'text-sm px-4 py-2',
    large: 'text-base px-[18px] py-[10px]'
  },
  icon: {
    xs: 'text-[13px] leading-4 pl-1 pr-[6px] py-[6px]',
    small: 'text-sm px-2 py-[6px]',
    medium: 'text-sm px-3 py-2',
    large: 'text-base px-[18px] py-[10px]'
  }
}

const sizeToIconStyle: Record<SizeType, React.ComponentProps<'p'>['className']> = {
  xs: 'p-[4px]',
  small: 'p-[6px]',
  medium: 'p-[10px]',
  large: 'p-[12px]'
}

const modeToStyle: Record<ModeType, Record<'default' | 'disabled', React.ComponentProps<'p'>['className']>> = {
  primary: {
    default: 'text-white bg-primary shadow-xs border-primary border-[2px] hover:bg-primaryDark',
    disabled: 'text-white bg-neutralLighter'
  },
  'primary-light': {
    default:
      'text-primaryDark shadow-xs bg-primaryLightest border-primaryLightest border-[2px] hover:bg-primaryLighter active:border-primary active:border-2',
    disabled: 'text-neutralLight bg-grayNeutral-50'
  },
  'primary-text': {
    default: 'text-primary hover:bg-primaryLightest active:text-primaryDark border-[transparent]',
    disabled: 'text-neutralLight'
  },
  'primary-link': {
    default: 'text-primary active:text-primaryDark focus:bg-brand-25',
    disabled: 'text-neutralLight'
  },
  secondary: {
    default: 'text-neutralDarkest border-neutralLighter border-[2px] shadow-xs bg-white hover:bg-grayNeutral-50',
    disabled: 'text-neutralLight border-neutralLighter border-[2px] bg-grayNeutral-50'
  },
  'secondary-text': {
    default: 'text-neutralDarkest hover:bg-grayNeutral-50 border-[transparent]',
    disabled: 'text-neutralLight'
  },
  'secondary-link': {
    default: 'text-neutralDarkest active:text-grayNeutral-900 focus:bg-grayNeutral-50',
    disabled: 'text-neutralLight'
  },
  negative: {
    default: 'text-white bg-negative shadow-xs border-negative border-[2px] hover:bg-negativeDarker',
    disabled: 'text-white bg-neutralLighter'
  },
  'negative-light': {
    default:
      'text-negative bg-negativeLightest shadow-xs border-negativeLightest border-[2px] hover:bg-negativeLighter active:border-negative active:border-2',
    disabled: 'text-neutralLight'
  },
  'negative-text': {
    default: 'text-negative hover:bg-negativeLightest',
    disabled: 'text-neutralLight'
  },
  'negative-link': {
    default: 'text-negative hover:text-negativeDarker',
    disabled: 'text-neutralLight'
  }
}

const modeToDotStyle: Record<ModeType, Record<'default' | 'disabled', React.ComponentProps<'p'>['className']>> = {
  primary: {
    default: 'text-white',
    disabled: 'text-white'
  },
  'primary-light': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  'primary-text': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  'primary-link': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  secondary: {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  'secondary-text': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  'secondary-link': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  negative: {
    default: 'text-white',
    disabled: 'text-white'
  },
  'negative-light': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  'negative-text': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  },
  'negative-link': {
    default: 'text-positive',
    disabled: 'bg-grayNeutral-400'
  }
}

/**
 * @group Components
 * @category Props
 */
export const Button = ({
  className,
  children,
  disabled,
  size = 'medium',
  mode = 'primary',
  icon,
  withDot,
  type,
  iconSide = 'left',
  ...restProps
}: Props) => {
  const iconOnly = !children && icon
  const sizeStyle = iconOnly ? sizeToIconStyle[size] : sizeToLabelStyle[icon ? 'icon' : 'default'][size]
  const modeStyle = iconOnly ? '' : modeToStyle[mode][disabled ? 'disabled' : 'default']
  const dotStyle = modeToDotStyle[mode][disabled ? 'disabled' : 'default']

  return (
    <button
      className={classnames(
        'rounded-lg flex items-center max-h-10 whitespace-nowrap w-auto z-10',
        disabled ? 'cursor-grab' : 'cursor-pointer',
        withDot ? 'gap-2' : 'gap-1',
        sizeStyle,
        modeStyle,
        iconSide === 'left' ? 'flex-row' : 'flex-row-reverse',
        className
      )}
      type={type}
      disabled={disabled}
      {...restProps}
    >
      {withDot && <Dot className={classnames(dotStyle, 'rounded-lg')} />}
      {icon && React.cloneElement(icon, { className: classnames(icon.props.className, size === 'xs' && 'scale-75') })}
      {typeof children === 'string' ? (
        <Label size="l" weight="semibold" className={classnames(!icon && 'w-full')}>
          {children}
        </Label>
      ) : (
        children
      )}
    </button>
  )
}
