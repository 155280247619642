import { createWithEqualityFn } from 'zustand/traditional'

type InstallAppStoreType = {
  isDialogVisible: boolean
}

const initialState: InstallAppStoreType = {
  isDialogVisible: false
}

const useStore = createWithEqualityFn(() => initialState)

export const installAppStore = {
  setDialogVisible: (): void => {
    useStore.setState({ isDialogVisible: true })
  },
  setDialogInvisible: (): void => {
    useStore.setState({ isDialogVisible: false })
  },

  clearStore: () => {
    useStore.setState(initialState, true)
  },

  useStore
}
