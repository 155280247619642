import { createJSONStorage, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { nativeStorage } from '@app/utils/nativeStorage'

type TokenStoreType = {
  accessToken: string | null
  refreshToken: string | null
  isLoggedWithQR: boolean

  newRegistrationData?: {
    classroomId: string
    classroomLoginCode: string
  }
}

const initialState: TokenStoreType = {
  isLoggedWithQR: false,
  refreshToken: null,
  accessToken: null
}

const useStore = createWithEqualityFn(
  persist<TokenStoreType>(() => initialState, {
    name: 'tokenStore',
    storage: createJSONStorage(() => nativeStorage())
  }),
  shallow
)

export const tokenStore = {
  saveAccessToken: (accessToken: string): void => {
    useStore.setState({ accessToken })
  },
  saveRefreshToken: (refreshToken: string): void => {
    useStore.setState({ refreshToken })
  },
  setIsLoggedWithQR: (isLoggedWithQR: boolean): void => {
    useStore.setState({ isLoggedWithQR })
  },

  setNewRegistrationData: (newRegistrationData: { classroomId: string; classroomLoginCode: string }): void => {
    useStore.setState({ newRegistrationData })
  },

  clearRegistrationData: (): void => {
    useStore.setState({ newRegistrationData: undefined })
  },

  setRefreshToken: (refreshToken: string): void => {
    useStore.setState({ refreshToken })
  },
  getRefreshToken: (): string | null => {
    const { refreshToken } = useStore.getState()
    return refreshToken
  },
  setAccessToken: (accessToken: string): void => {
    useStore.setState({ accessToken })
  },
  getAccessToken: (): string | null => {
    const { accessToken } = useStore.getState()
    return accessToken
  },

  clearStore: () => {
    useStore.setState(initialState, true)
  },

  useStore
}
