export const Pages = {
  ROOT: '',

  // Login page
  LOGIN: '/login',
  QR_LOGIN: '/qr-login',

  // Dashboard pages
  DASHBOARD: '/app/dashboard',
  DASHBOARD_LEARNING_PATH: '/app/dashboard/learningPath', // Learning path
  DASHBOARD_TASKS: '/app/dashboard/tasks', // Tasks
  DASHBOARD_TASKS_FINISHED: '/app/dashboard/tasks/finished', // Tasks
  DASHBOARD_TASKS_DETAIL: (id: string, type: string) => `/app/dashboard/tasks/${id}/${type}`,
  DASHBOARD_LEADER_BOARD: '/app/dashboard/leaderboard', // Leaderboard
  DASHBOARD_MY_PROFILE: '/app/dashboard/my-profile', // My profile

  // Settings pages
  SETTINGS: '/app/settings',
  SETTINGS_APP_LANGUAGE: '/app/settings/app-language',
  SETTINGS_DEVELOPER_MODE: '/app/settings/developer-mode',
  SETTINGS_PROFILE_PICTURE: '/app/settings/profile-picture',
  SETTINGS_ADD_CLASSROOM: '/app/settings/add-classroom',
  // SETTINGS_USER_INFO: `/app/settings/user-info`,

  // Lesson pages
  LESSON_BEFORE: `/app/lesson/lesson-before`,
  LESSON_TRAINING: '/app/lesson/lesson-training',
  LESSON_END: '/app/lesson/lesson-end',

  // Social activities pages
  SOCIAL_ACTIVITY_LOGIN: '/app/sa-login',
  QUIZ_GAME: '/app/quiz-game'
} as const
