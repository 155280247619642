import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu'
import { default as classNames, default as classnames } from 'classnames'
import { ReactNode, Ref, forwardRef } from 'react'

export type DropdownOption = DropdownMenuItemProps & {
  item: ReactNode
}

type Props = DropdownMenu.DropdownMenuProps & {
  disabled?: boolean
  className?: string
  options: DropdownOption[]
  triggerProps?: DropdownMenu.DropdownMenuTriggerProps
  contentProps?: DropdownMenu.DropdownMenuContentProps
}

/**
 * @group Components
 * @category Component
 */
export const Dropdown = forwardRef(
  (
    { className, children, disabled, options = [], triggerProps, contentProps, ...restProps }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return options.length > 0 ? (
      <div className={classnames('flex flex-col gap-2 min-w-[40px]', className)} ref={ref}>
        <DropdownMenu.Root {...restProps}>
          <DropdownMenu.Trigger asChild disabled={disabled} {...triggerProps}>
            <div>{children}</div>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              {...contentProps}
              className={classnames(
                'p-1 bg-white shadow-l mt-2 z-50 border-[1px] border-grayNeutral-200 rounded-lg shadow-sm cursor-pointer min-w-fit',
                contentProps?.className
              )}
            >
              {options.map((item, index) => {
                return (
                  <DropdownMenu.Item
                    key={index}
                    className={classNames(
                      'flex h-10 px-2 py-[10px] text-sm rounded-md outline-none',
                      item.disabled ? 'text-grayNeutral-300 cursor-not-allowed' : 'hover:bg-grayNeutral-100'
                    )}
                    {...item}
                  >
                    {item.item}
                  </DropdownMenu.Item>
                )
              })}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    ) : null
  }
)
