import { useState } from 'react'

import { Button, Heading } from '@app/components'
import { useTranslation } from '@app/locales'

import { useReportBug } from '../../data'
import { lessonActiveFlashCardStore } from '../../store'
import { IssueType } from './enums'

type Props = {
  onClose: () => void
  issueType: IssueType
}

export const IssueReportMenu = ({ onClose, issueType }: Props) => {
  const [postContent, setPostContent] = useState('')

  const { t } = useTranslation(['training', 'common'])

  const bugReportParams = lessonActiveFlashCardStore.useStore((store) => store.bugReportParams)
  const { reportBug, loading } = useReportBug(bugReportParams)

  const handleOnSubmitClick = async () => {
    await reportBug(t(`inputDialog.${issueType}`) + ': ' + postContent)
    onClose()
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-between gap-1 p-4">
      <Heading size="xl" className="mt-4 text-center text-neutral1000">
        {t(`inputDialog.${issueType}`)}
      </Heading>

      <div className="flex w-full flex-col items-center justify-center rounded-3xl py-3">
        <textarea
          disabled={loading}
          rows={5}
          placeholder={t('inputDialog.placeholder')}
          className="w-full resize-none rounded-3xl p-4 text-base shadow-card"
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
        />
      </div>

      <div className="flex w-full flex-col gap-2">
        <Button
          className="w-full"
          mode="primary"
          onClick={handleOnSubmitClick}
          disabled={loading || postContent.length === 0}
        >
          {t('submit')}
        </Button>

        <Button className="w-full" mode="outline" onClick={onClose} disabled={loading}>
          {t('close')}
        </Button>
      </div>
    </div>
  )
}
