import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { useTimeout } from '@foxino/components-common'

import { Text } from '@app/components'
import { useTranslation } from '@app/locales'

type Props = {
  isQrScan: boolean
  toggle: () => void
}

const OPTION_WIDTH = 171

export const InputTypeSwitch = ({ isQrScan, toggle }: Props) => {
  const { t } = useTranslation('login')

  const [isReady, setIsReady] = useState(false)
  const [callQrCodeResetTimeout] = useTimeout(250)

  useEffect(() => {
    callQrCodeResetTimeout(() => setIsReady(true))
  }, [])

  return (
    <div className={classNames('absolute bottom-8 flex w-full justify-center')}>
      {isReady && (
        <div
          style={{
            background: '#DED9FF',
            padding: '2px',
            width: OPTION_WIDTH * 2 + 4 // 4 padding
          }}
          className={`${isQrScan ? 'justify-end' : 'justify-start'} relative flex h-12 cursor-pointer flex-row rounded-[24px]`}
          onClick={toggle}
        >
          <motion.div
            style={{ width: OPTION_WIDTH }}
            className="z-20 flex h-full items-center justify-center rounded-[24px] bg-btn-primary-bg-gradient text-neutral50 shadow-btn-primary"
            layout
            transition={{ type: 'spring', duration: 0.25 }}
          >
            <Text size="l" weight="bold">
              {isQrScan ? t('loginQR.scan') : t('loginQR.writeCode')}
            </Text>
          </motion.div>

          <div
            style={{ width: OPTION_WIDTH, background: '#DED9FF' }}
            className="absolute left-0 z-10 flex h-[44px] items-center justify-center rounded-[24px] text-[#2C0077]"
          >
            <Text size="l" weight="bold">
              {t('loginQR.writeCode')}
            </Text>
          </div>

          <div
            style={{ width: OPTION_WIDTH, background: '#DED9FF' }}
            className="absolute right-0 z-10 flex h-[44px] items-center justify-center rounded-[24px] text-[#2C0077]"
          >
            <Text size="l" weight="bold">
              {t('loginQR.scan')}
            </Text>
          </div>
        </div>
      )}
    </div>
  )
}
