import { useEffect, useState } from 'react'
import { NetworkStatus } from '@apollo/client'

import { GetFinishedTasksQuery, useGetFinishedTasksLazyQuery } from '@app/data'
import { logError } from '@app/utils/logsUtils'

import { PAGE_COUNT } from './useFinishedTasks'

export const useFinishedTasksLazy = (shouldGetNextTasksOnLoad = false, classroomId?: string) => {
  const [allTasks, setAllTasks] = useState<GetFinishedTasksQuery['tasks'] | null>(null)
  const [page, setPage] = useState(0)

  const [getFinishedTasksLazyQuery, getFinishedTasksLazyQueryData] = useGetFinishedTasksLazyQuery()

  useEffect(() => {
    const getInitialTasks = async () => {
      try {
        const result = await getFinishedTasksLazyQuery({
          variables: {
            classroomId,
            page: { from: 0, count: PAGE_COUNT }
          },
          fetchPolicy: 'cache-and-network'
        })

        if (shouldGetNextTasksOnLoad && result.data?.tasks?.length === PAGE_COUNT) {
          fetchMoreTasks()
        }
      } catch (error) {
        logError(error, 'useFinishedTasksLazy', 'useEffect')
      }
    }

    getInitialTasks()
  }, [])

  useEffect(() => {
    if (getFinishedTasksLazyQueryData.data?.tasks) {
      setAllTasks((prevTasks) => {
        const previousTasks = prevTasks || []

        const newTasks =
          getFinishedTasksLazyQueryData.data?.tasks.filter(
            (newTask) => !previousTasks.some((prevTask) => prevTask.id === newTask.id)
          ) || []

        return [...previousTasks, ...newTasks]
      })
    }
  }, [getFinishedTasksLazyQueryData.data?.tasks])

  const fetchMoreTasks = async () => {
    setPage((prevPage) => prevPage + 1)

    try {
      await getFinishedTasksLazyQuery({
        variables: {
          classroomId,
          page: { from: (page + 1) * PAGE_COUNT, count: PAGE_COUNT }
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
      })
    } catch (error) {
      logError(error, 'useFinishedTasksLazy', 'fetchMoreTasks')
    }
  }

  return {
    refetch: getFinishedTasksLazyQueryData.refetch,
    refetching: getFinishedTasksLazyQueryData.networkStatus === NetworkStatus.refetch,
    loading: getFinishedTasksLazyQueryData.loading,
    error: getFinishedTasksLazyQueryData.error,
    finishedTasks: allTasks,
    hasMore: getFinishedTasksLazyQueryData.data?.tasks?.length === PAGE_COUNT,
    actions: {
      fetchMoreTasks
    }
  }
}
