import { Suspense, useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { registerSW } from 'virtual:pwa-register'

import { initializeSentry, isSentryInitialized } from '@app/config/analytics/initializeSentry'
import { browserRouter } from '@app/config/router/browserRouter'
import i18n from '@app/locales/i18n'
import { GraphqlProvider } from '@app/modules/common/providers'

import { KeycloakProvider } from './modules/auth/providers/KeycloakProvider'
import { isNative } from './utils/platformUtils'

registerSW({ immediate: true })

if (!isSentryInitialized() && import.meta.env.DEV === false) {
  initializeSentry()
}

const App = () => {
  useEffect(() => {
    const style = document.createElement('style')

    if (isNative()) {
      // hide all scrollbars on native app
      style.textContent = `
      * {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
      }
      *::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
      }
    `
    }

    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <KeycloakProvider>
        <GraphqlProvider>
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={true}
            closeButton={true}
            draggable={true}
            draggablePercent={10}
          />
          {/* Test of suspense to get rid of errors: "Cannot access 'MainLayout' before initialization" */}
          <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={browserRouter} />
          </Suspense>
        </GraphqlProvider>
      </KeycloakProvider>
    </I18nextProvider>
  )
}

export default App
