import classNames from 'classnames'
import { ReactNode, forwardRef } from 'react'
import { ChevronRight, FaceHappy } from '@untitled-ui/icons-react'

type Props = React.HTMLProps<HTMLDivElement> & {
  Icon?: ReactNode
  label: string
  description?: string
  count?: number
}

/**
 * @group Components
 * @category Component
 */
export const Card = forwardRef(
  (
    { className, Icon, label, description, onClick, count = 0, ...restProps }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const IconComponent = Icon || <FaceHappy />

    return (
      <div
        className={classNames(
          'px-4 py-3 flex flex-row bg-white min-h-[24px] min-w-[228px] shadow-xs items-center justify-between gap-3',
          'border-grayNeutral-300 rounded-lg border-[1px]',
          onClick && 'hover:cursor-pointer hover:bg-grayNeutral-50',
          className
        )}
        ref={ref}
        onClick={onClick}
        {...restProps}
      >
        <div className="flex flex-row items-center gap-3">
          {IconComponent}
          <div className="text-sm flex flex-col">
            <label className="font-bold">{label}</label>
            {description && <p className="font-medium text-neutralDark">{description}</p>}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <p className="text-sm text-gray-900 font-bold">{count}</p>
          <ChevronRight className="text-grayNeutral-500 scale-75" />
        </div>
      </div>
    )
  }
)
