import { AlertCircle, HelpCircle } from '@untitled-ui/icons-react'
import classnames from 'classnames'
import { cloneElement, forwardRef, InputHTMLAttributes, ReactElement, ReactNode } from 'react'

import { IconType } from '..'
import { Tooltip } from '../Tooltip'

type VariantType = 'outlined' | 'simple'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  iconLeft?: ReactElement<IconType> | ReactElement<any>
  leftPart?: ReactNode
  rightPart?: ReactNode
  disabled?: boolean
  error?: boolean
  label?: string
  hint?: string
  description?: string
  errorText?: string
  variant?: VariantType
}

const variantToStyle: Record<VariantType, string> = {
  outlined: 'border-[2px] focus-within:border-2 px-[14px]',
  simple: ''
}

const validClasses = 'bg-white border-grayNeutral-300 placeholder:text-grayNeutral-500 focus-within:border-primary'
const errorClasses = 'bg-white border-negative placeholder:text-grayNeutral-500 focus-within:border-negative'
const disabledClasses = 'bg-grayNeutral-50 text-grayNeutral-400 border-grayNeutral-200 placeholder:text-grayNeutral-500'

/**
 * @group Components
 * @category Component
 */
export const TextInput = forwardRef(
  (
    {
      className,
      children,
      disabled,
      iconLeft,
      leftPart,
      rightPart,
      description,
      error,
      errorText,
      hint,
      label,
      type = 'text',
      inputMode = 'text',
      variant = 'outlined',
      ...restProps
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasError = !!errorText || error
    const variantStyle = variantToStyle[variant]

    return (
      <div className={classnames('flex flex-col gap-2 text-sm', className)}>
        {label ? <label className="text-grayNeutral-900">{label}</label> : null}
        <div
          className={classnames(
            'flex flex-row gap-1 rounded-lg items-center h-[40px]',
            disabled ? disabledClasses : hasError ? errorClasses : validClasses,
            variantStyle
          )}
        >
          {leftPart}
          {iconLeft &&
            cloneElement(iconLeft, {
              className: classnames(
                disabled ? 'text-grayNeutral-400' : 'text-grayNeutral-600',
                iconLeft.props.className
              )
            })}
          <input
            ref={ref}
            type={type}
            inputMode={inputMode}
            disabled={disabled}
            className={classnames(
              'flex-1 items-center focus:outline-none w-full rounded-md',
              disabled ? 'text-grayNeutral-400' : 'text-grayNeutral-900'
            )}
            {...restProps}
          />
          {hasError && !disabled ? (
            <AlertCircle className="text-negative" />
          ) : hint ? (
            <Tooltip label={hint} side="top" disabled={disabled}>
              <HelpCircle className="text-grayNeutral-500" />
            </Tooltip>
          ) : null}
          {rightPart}
        </div>
        {errorText && !disabled ? (
          <p className="text-sm text-negative">{errorText}</p>
        ) : description ? (
          <p className="text-sm text-grayNeutral-500">{description}</p>
        ) : null}
      </div>
    )
  }
)
