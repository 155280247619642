import Keycloak from 'keycloak-js'
import { createJSONStorage, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { logError } from '@app/utils/logsUtils'
import { nativeStorage } from '@app/utils/nativeStorage'

import { AuthState } from '../model/enums'

type TenantInfo = {
  name: string
  realm: string
  keycloakUri: string
}

type UserInfoType = {
  userId: string
  firstName: string
  lastName: string
  email: string
  fullName: string
  roles?: string[]
}

type AuthStoreType = {
  tenant?: TenantInfo
  accessToken?: string
  refreshToken?: string
  idToken?: string
  authState: AuthState
  refreshTokenTimerId?: NodeJS.Timeout
  userInfo: UserInfoType
  isTransparencyChecked?: boolean
  keycloak?: Keycloak
  isRehydrated?: boolean
}

const initialState: AuthStoreType = {
  authState: AuthState.UNKNOWN,
  userInfo: {
    email: '',
    firstName: '',
    fullName: '',
    lastName: '',
    userId: '',
    roles: undefined
  }
}

const useStore = createWithEqualityFn(
  persist<AuthStoreType>(() => initialState, {
    name: 'auth-store',
    storage: createJSONStorage(nativeStorage),
    merge: (persistedState, currentState) => {
      return {
        ...currentState,
        ...(persistedState ? persistedState : {}),
        isRehydrated: true
      }
    },
    onRehydrateStorage: () => {
      console.info('[Hydration] - starts')

      return (_, error) => {
        if (error) {
          logError(error, 'authStore', 'persist', 'an error happened during hydration', false)
        } else {
          console.info('[Hydration] - finished')
        }
      }
    }
  }),
  shallow
)

export const authStore = {
  setIsRehydrated: (isRehydrated: boolean): void => {
    useStore.setState({ isRehydrated })
  },
  setAuthState: (authState: AuthState): void => {
    useStore.setState({ authState })
  },
  saveIdToken: (idToken: string | undefined): void => {
    useStore.setState({ idToken })
  },
  saveAccessToken: (accessToken: string | undefined): void => {
    useStore.setState({ accessToken })
  },
  saveRefreshToken: (refreshToken: string | undefined): void => {
    useStore.setState({ refreshToken })
  },
  isAuthorized: (): boolean => {
    return (
      useStore.getState().authState === AuthState.AUTHENTICATED ||
      useStore.getState().authState === AuthState.AUTHENTICATED_QR
    )
  },
  setRefreshTokenTimerId: (refreshTokenTimerId: NodeJS.Timeout): void => {
    useStore.setState({ refreshTokenTimerId })
  },
  getRefreshTokenTimerId: (): NodeJS.Timeout | undefined => {
    return useStore.getState().refreshTokenTimerId
  },
  clearRefreshTokenTimerId: (): void => {
    useStore.setState({ refreshTokenTimerId: undefined })
  },
  setUserInfo: (userInfo: UserInfoType): void => {
    useStore.setState({ userInfo })
  },

  clearStore: (): void => {
    useStore.setState(initialState, false)
  },
  useStore
}
