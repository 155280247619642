import classnames from 'classnames'
import React, { forwardRef } from 'react'
import foxImage from './foxImage.webp'

type SizeType = 's' | 'l' | 'xl'

const sizeToStyle: Record<SizeType, string> = {
  s: 'w-8 h-8',
  l: 'w-[84px] h-[84px]',
  xl: 'w-[112px] h-[112px]'
}

const sizeToRadius: Record<SizeType, string> = {
  s: 'rounded',
  l: 'rounded-lg',
  xl: 'rounded-xl'
}

export type ActivityImage = Omit<React.HTMLProps<HTMLImageElement>, 'src' | 'size'> & {
  url?: string | null
  size?: SizeType
}

/**
 * @group Components
 * @category Component
 */
export const ActivityImage = forwardRef(
  ({ className, url, size = 's', ...restProps }: ActivityImage, ref: React.Ref<HTMLImageElement>) => {
    const src = url || (foxImage as unknown as string)
    const sizeStyle = sizeToStyle[size]
    const radiusStyle = sizeToRadius[size]

    return (
      <img
        ref={ref}
        src={src}
        alt="ActivityImage"
        className={classnames('object-cover', sizeStyle, radiusStyle)}
        {...restProps}
      />
    )
  }
)
