import { CSSProperties } from 'react'
import classNames from 'classnames'

import Constants from '@app/constants'

type LearningExecutionProgressBarProps = {
  className?: string
  progressClassName?: string
  progressStyle?: CSSProperties
  progress: number
}

export const LearningExecutionProgressBar = ({
  className,
  progressClassName,
  progressStyle,
  progress
}: LearningExecutionProgressBarProps) => {
  const calculatedProgress =
    progress > Constants.MAX_VISIBLE_PROGRESS
      ? Constants.MAX_VISIBLE_PROGRESS
      : progress < Constants.MIN_VISIBLE_PROGRESS
        ? Constants.MIN_VISIBLE_PROGRESS
        : progress

  return (
    <div style={{ backgroundColor: '#CDC8F6' }} className={classNames('h-2 w-full max-w-24 rounded-full', className)}>
      <div
        style={{ width: `${calculatedProgress}%`, ...progressStyle }}
        className={classNames('h-2 min-w-2 rounded-full bg-main-bg-gradient', progressClassName)}
      />
    </div>
  )
}
