import * as RadixTooltip from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import { ReactNode } from 'react'

type Props = RadixTooltip.TooltipContentProps & {
  className?: string
  label: string | ReactNode
  description?: string
  children: ReactNode
  disabled?: boolean
  dark?: boolean
}

/**
 * @group Components
 * @category Component
 */
export const Tooltip = ({ className, label, description, children, disabled, dark, ...restProps }: Props) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root open={disabled ? false : undefined}>
        <RadixTooltip.Trigger disabled={disabled} type="button">
          {children}
        </RadixTooltip.Trigger>

        <RadixTooltip.Portal>
          <div className="shadow-l">
            <RadixTooltip.Content
              sideOffset={15}
              side="right"
              className={classNames(
                'rounded-lg px-[12px] py-2 shadow-l z-50',
                dark ? 'bg-grayNeutral-1000' : 'bg-white'
              )}
              {...restProps}
            >
              <p className={classNames(dark ? 'text-white' : 'text-neutralDarkest', description && 'font-bold')}>
                {label}
              </p>
              {description && (
                <p className={classNames('text-sm', dark ? 'text-white' : 'text-grayNeutral-500')}>{description}</p>
              )}
              <RadixTooltip.Arrow className={dark ? 'fill-grayNeutral-1000' : 'fill-white'} />
            </RadixTooltip.Content>
          </div>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
