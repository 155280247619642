import { SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

export const ReadyIcon = ({ color = '#4D5761', ...restProps }: Props): JSX.Element => {
  return (
    <svg
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.80258 11.5275C4.53258 11.8425 5.31258 12 6.14258 12C6.97258 12 7.75258 11.8425 8.48258 11.5275C9.21258 11.2125 9.84758 10.785 10.3876 10.245C10.9276 9.705 11.3551 9.07 11.6701 8.34C11.9851 7.61 12.1426 6.83 12.1426 6C12.1426 5.17 11.9851 4.39 11.6701 3.66C11.3551 2.93 10.9276 2.295 10.3876 1.755C9.84758 1.215 9.21258 0.7875 8.48258 0.4725C7.75258 0.1575 6.97258 0 6.14258 0C5.31258 0 4.53258 0.1575 3.80258 0.4725C3.07258 0.7875 2.43758 1.215 1.89758 1.755C1.35758 2.295 0.930078 2.93 0.615078 3.66C0.300078 4.39 0.142578 5.17 0.142578 6C0.142578 6.83 0.300078 7.61 0.615078 8.34C0.930078 9.07 1.35758 9.705 1.89758 10.245C2.43758 10.785 3.07258 11.2125 3.80258 11.5275ZM9.84968 4.70711C10.2402 4.31658 10.2402 3.68342 9.84968 3.29289C9.45916 2.90237 8.826 2.90237 8.43547 3.29289L5.14258 6.58579L3.84968 5.29289C3.45916 4.90237 2.826 4.90237 2.43547 5.29289C2.04495 5.68342 2.04495 6.31658 2.43547 6.70711L4.43547 8.70711C4.62301 8.89464 4.87736 9 5.14258 9C5.40779 9 5.66215 8.89464 5.84968 8.70711L9.84968 4.70711Z"
        fill="currentColor"
      />
    </svg>
  )
}
