import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { AuthClientEvent, AuthClientInitOptions, AuthClientTokens } from '@react-keycloak/core/lib/types'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import Keycloak from 'keycloak-js'

import { LoaderWrapper } from '@app/components'
import Constants from '@app/constants'

import { initAuth } from '../actions/authActions'
import { AuthState } from '../model/enums'
import { UserInfoDTO } from '../model/types'
import { authStore } from '../store/authStore'
import { tokenStore } from '../store/tokenStore'

const keycloak = new Keycloak({
  url: Constants.AUTH_ENDPOINT,
  clientId: Constants.AUTH_KEYCLOAK_CLIENT_ID,
  realm: Constants.DEFAULT_TENANT
})

// eslint-disable-next-line react-refresh/only-export-components
export const useHandleKeycloakTokens = () => {
  return useCallback((tokens: AuthClientTokens) => {
    if (tokens.token) {
      authStore.saveAccessToken(tokens.token)
      tokenStore.saveAccessToken(tokens.token)

      const decodedInfo = jwtDecode<JwtPayload & UserInfoDTO>(tokens.token)
      const { preferred_username, email, family_name, given_name, name, realm_access } = decodedInfo
      authStore.setUserInfo({
        userId: preferred_username,
        email,
        firstName: given_name,
        fullName: name,
        lastName: family_name,
        roles: realm_access?.roles || []
      })
    } else {
      if (!tokenStore.useStore.getState().isLoggedWithQR) {
        tokenStore.clearStore()
      }
    }

    if (tokens.refreshToken) {
      authStore.saveRefreshToken(tokens.refreshToken)
      tokenStore.saveRefreshToken(tokens.refreshToken)
    } else {
      if (!tokenStore.useStore.getState().isLoggedWithQR) {
        tokenStore.clearStore()
      }
    }

    authStore.setAuthState(tokens.token ? AuthState.AUTHENTICATED : AuthState.NEED_TO_LOGIN)
  }, [])
}

type Props = {
  children: ReactNode
}

export const KeycloakProvider = (props: Props): ReactElement => {
  const handleNewTokens = useHandleKeycloakTokens()

  const { idToken, refreshToken, accessToken } = authStore.useStore.getState()
  // const hasHydrated = authStore.useStore.persist.hasHydrated()

  const isRehydrated = authStore.useStore(({ isRehydrated }) => isRehydrated)

  const initOptions = useMemo<AuthClientInitOptions>(
    () => ({
      enableLogging: true,
      checkLoginIframe: false,
      redirectUri: `${window.location.origin}/app/dashboard`,
      refreshToken,
      idToken,
      token: accessToken
    }),
    [accessToken, idToken, refreshToken]
  )

  const handleOnEvent = (eventType: AuthClientEvent) => {
    if (eventType === 'onAuthError') {
      keycloak.clearToken()
      tokenStore.clearStore()
      authStore.clearStore()
    }
  }

  useEffect(() => {
    initAuth()
    authStore.useStore.setState({ keycloak })
  }, [])

  if (!isRehydrated) {
    return <LoaderWrapper loading className="h-screen" />
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      onTokens={handleNewTokens}
      onEvent={handleOnEvent}
      autoRefreshToken
    >
      {props.children}
    </ReactKeycloakProvider>
  )
}
