import { NetworkStatus, QueryHookOptions } from '@apollo/client'

import { GetFinishedTasksQuery, GetFinishedTasksQueryVariables, useGetFinishedTasksQuery } from '@app/data'

export const PAGE_COUNT = 6

export const useFinishedTasks = (
  baseOptions?: QueryHookOptions<GetFinishedTasksQuery, GetFinishedTasksQueryVariables>
) => {
  const query = useGetFinishedTasksQuery({
    ...baseOptions,
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: 'overwrite',
    nextFetchPolicy: 'network-only'
  })

  return {
    refetch: query.refetch,
    refetching: query.networkStatus === NetworkStatus.refetch,
    loading: query.loading,
    error: query.error,
    finishedTasks: query.data?.tasks,
    hasMore: query.data?.tasks?.length === PAGE_COUNT
  }
}
