import classNames from 'classnames'
import { forwardRef } from 'react'

type SizeType = 'small' | 'normal' | 'big'
type IconSide = 'left' | 'right'

export type BadgeVariantType =
  | 'gray'
  | 'primary'
  | 'error'
  | 'success'
  | 'warning'
  | 'blueGray'
  | 'blueLight'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'rose'
  | 'orange'

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'size'> & {
  size?: SizeType
  label?: string
  disabled?: boolean
  variant?: BadgeVariantType
  dot?: boolean
  imageUrl?: string
  icon?: React.ReactNode
  iconSide?: IconSide
}

const sizeToTextStyle: Record<SizeType, string> = {
  small: 'text-xs ',
  normal: 'text-sm',
  big: 'text-sm'
}

type PaddingStyleType = 'label' | 'dot' | 'image' | 'icon-left' | 'icon-right' | 'icon-only'

const sizeToPaddingStyle: Record<PaddingStyleType, Record<SizeType, string>> = {
  label: {
    small: 'px-2 py-[2px]',
    normal: 'px-[10px] py-[2px]',
    big: 'px-3 py-1'
  },
  dot: {
    small: 'pl-[6px] pr-[8px] py-[2px]',
    normal: 'pl-[8px] pr-[10px] py-[2px]',
    big: 'pl-[10px] pr-[12px] py-1'
  },
  image: {
    small: 'pl-[3px] pr-[8px] py-[2px]',
    normal: 'pl-[4px] pr-[10px] py-[2px]',
    big: 'pl-[6px] pr-[12px] py-1'
  },
  'icon-left': {
    small: 'pl-[2px] pr-[8px] py-[2px]',
    normal: 'pl-[2px] pr-[10px] py-[2px]',
    big: 'pl-[6px] pr-[12px] py-1'
  },
  'icon-right': {
    small: 'pl-[8px] pr-[2px] py-[2px]',
    normal: 'pl-[10px] pr-[2px] py-[2px]',
    big: 'pl-[12px] pr-[6px] py-1'
  },
  'icon-only': {
    small: 'p-1',
    normal: 'p-[6px]',
    big: 'p-2'
  }
}

const variantToStyle: Record<BadgeVariantType, string> = {
  gray: 'bg-grayNeutral-100 text-grayNeutral-500',
  primary: 'bg-primaryLightest text-primary',
  error: 'bg-negativeLightest text-negative',
  warning: 'bg-warningLightest text-warningBase',
  success: 'bg-positiveLightest text-positive',
  blueGray: 'bg-accentBlueGrayLightest text-accentBlueGray',
  blueLight: 'bg-accentBlueLightLightest text-accentBlueLight',
  blue: 'bg-accentBlueLightest text-accentBlue',
  indigo: 'bg-accentIndigoLightest text-accentIndigoDark',
  purple: 'bg-accentPurpleLightest text-accentPurple',
  pink: 'bg-accentPinkLightest text-accentPinkDark',
  rose: 'bg-accentRoseLightest text-accentRoseDark',
  orange: 'bg-accentOrangeLightest text-accentOrangeDark'
}

const variantToDotStyle: Record<BadgeVariantType, string> = {
  gray: 'bg-grayNeutral-500',
  primary: 'bg-primary',
  error: 'bg-negative',
  warning: 'bg-warningBase',
  success: 'bg-positive',
  blueGray: 'bg-accentBlueGrayLight',
  blueLight: 'bg-accentBlueLightLight',
  blue: 'bg-accentBlueLight',
  indigo: 'bg-accentIndigoLight',
  purple: 'bg-accentPurpleLight',
  pink: 'bg-accentPinkLight',
  rose: 'bg-accentRoseLight',
  orange: 'bg-accentOrangeLight'
}

/**
 * @group Components
 * @category Component
 */
export const Badge = forwardRef(
  (
    {
      className,
      onClick,
      size = 'normal',
      variant = 'gray',
      iconSide = 'right',
      disabled,
      label,
      dot,
      imageUrl,
      icon,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const iconOnly = !label
    const textSizeStyle = sizeToTextStyle[size]
    const colorStyle = variantToStyle[variant]
    const dotStyle = variantToDotStyle[variant]
    const paddingType = iconOnly
      ? 'icon-only'
      : dot
      ? 'dot'
      : imageUrl
      ? 'image'
      : icon && iconSide === 'left'
      ? 'icon-left'
      : icon && iconSide === 'right'
      ? 'icon-right'
      : 'label'
    const paddingStyle = sizeToPaddingStyle[paddingType][size]

    return (
      <div
        ref={ref}
        className={classNames(
          'h-full flex justify-between flex-row items-center',
          textSizeStyle,
          colorStyle,
          paddingStyle,
          iconOnly ? 'rounded-full' : 'rounded-2xl',
          !icon && 'gap-1',
          className
        )}
        {...restProps}
      >
        {imageUrl && (
          <div className="w-4 h-4">
            <img src={imageUrl} className="object-cover rounded-full" width={16} height={16} />
          </div>
        )}
        {dot && <span className={classNames('w-[6px] h-[6px] rounded-full', dotStyle)} />}
        {icon && iconSide === 'left' && <div className="flex items-center w-5 h-5">{icon}</div>}
        {label && <label>{label}</label>}
        {icon && iconSide === 'right' && <div className="flex items-center h-5 mx-1">{icon}</div>}
      </div>
    )
  }
)
