import { cloneElement, forwardRef, InputHTMLAttributes, ReactElement, ReactNode, useState } from 'react'
import classnames from 'classnames'
import { AlertCircle, Eye, EyeOff, HelpCircle } from '@untitled-ui/icons-react'

import { Tooltip } from '@foxino/components-web'

import { IconType, Text } from '..'

type VariantType = 'outlined' | 'simple'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  iconLeft?: ReactElement<IconType> | ReactElement<any>
  leftPart?: ReactNode
  rightPart?: ReactNode
  disabled?: boolean
  error?: boolean
  label?: string
  hint?: string
  description?: string
  errorText?: string
  variant?: VariantType
  isPasswordVisibility?: boolean
}

const variantToStyle: Record<VariantType, string> = {
  outlined: 'border-[2px] focus-within:border-2',
  simple: ''
}

const validClasses = 'bg-white border-grayNeutral-300 placeholder:text-grayNeutral-500 focus-within:border-primary'
const errorClasses = 'bg-white border-negative placeholder:text-grayNeutral-500 focus-within:border-negative'
const disabledClasses = 'bg-grayNeutral-50 text-grayNeutral-400 border-grayNeutral-200 placeholder:text-grayNeutral-500'

/**
 * @group Components
 * @category Component
 */
export const TextInput = forwardRef(
  (
    {
      className,
      disabled,
      iconLeft,
      leftPart,
      rightPart,
      description,
      error,
      errorText,
      hint,
      label,
      type = 'text',
      inputMode = 'text',
      variant = 'outlined',
      isPasswordVisibility,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false)

    const hasError = !!errorText || error
    const variantStyle = variantToStyle[variant]

    const togglePasswordVisibility = () => {
      setPasswordVisible((prev) => !prev)
    }

    return (
      <div className={classnames('relative flex flex-col gap-1 text-sm', className)}>
        {label ? (
          <Text size="m" className="text-grayNeutral-25">
            {label}
          </Text>
        ) : null}
        <div
          className={classnames(
            'flex h-[48px] flex-row items-center rounded-[43px]',
            disabled ? disabledClasses : hasError ? errorClasses : validClasses,
            variantStyle
          )}
        >
          {leftPart}
          {iconLeft &&
            cloneElement(iconLeft, {
              className: classnames(disabled ? 'text-neutral-50' : 'text-neutral-100', iconLeft.props.className)
            })}
          <input
            ref={ref}
            type={passwordVisible ? 'text' : type}
            inputMode={inputMode}
            disabled={disabled}
            className={classnames(
              'h-full w-full flex-1 items-center rounded-[43px] px-[14px] text-base font-light focus:outline-none',
              disabled ? 'text-grayNeutral-400' : 'text-grayNeutral-900',
              isPasswordVisibility ? 'pr-10' : ''
            )}
            {...restProps}
          />
          {hasError && !disabled ? (
            <AlertCircle className="text-negative" />
          ) : hint ? (
            <Tooltip label={hint} side="top" disabled={disabled}>
              <HelpCircle className="text-grayNeutral-500" />
            </Tooltip>
          ) : null}
          {rightPart}
          {isPasswordVisibility && (
            <div className="absolute right-2 cursor-pointer text-black">
              {passwordVisible ? (
                <EyeOff onClick={togglePasswordVisibility} />
              ) : (
                <Eye onClick={togglePasswordVisibility} />
              )}
            </div>
          )}
        </div>
        {errorText && !disabled ? (
          <p className="text-sm text-[#FF004D]">{errorText}</p>
        ) : description ? (
          <p className="text-sm text-grayNeutral-500">{description}</p>
        ) : null}
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'
