import { Dialog, DialogPanel } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'

import { profilePicturesContent } from '@foxino/components-common'

import { Button, Heading } from '@app/components'
import { useTranslation } from '@app/locales'

import { StudentLoginData } from '../model/types'
import { getFullName } from '../utils/getFullName'

type Props = {
  isDialogVisible: boolean
  student?: StudentLoginData
  onConfirm: () => void
  onClose: () => void
}

export const UserDialog = ({ student, onConfirm, isDialogVisible, onClose }: Props) => {
  const { t } = useTranslation(['login'])
  const { pictureUrl, firstName, lastName } = student || {}

  const { backgroundColor, image } = profilePicturesContent[pictureUrl || 'image1']

  return (
    <AnimatePresence>
      {isDialogVisible && (
        <Dialog static open={isDialogVisible} onClose={onClose} className="relative z-50">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center px-2">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="max-w-[300px] rounded-3xl bg-white bg-btn-primary-outline-bg-gradient p-4 shadow-2xl md:max-w-[400px]"
            >
              <div className="flex flex-col items-center gap-8 overflow-hidden p-4">
                <div className="flex flex-col items-center gap-4">
                  <div style={{ backgroundColor }} className="h-32 w-32 rounded-full p-4">
                    <img src={image} className="h-full w-full" />
                  </div>
                  <Heading className="text-center text-[#2C0077]">{getFullName(firstName, lastName)}</Heading>
                </div>

                <div className="flex flex-col items-center gap-4">
                  <span className="text-center text-[20px] text-[#52287C]">{t('loginQR.tip')}</span>
                  <Button btnClassName="px-6" onClick={onConfirm}>
                    {t('loginQR.confirm')}
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
