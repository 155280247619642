import { Language } from '@app/data'

export const languageToCodeMap: Record<Language, string> = {
  CZECH: 'cs-CZ',
  ENGLISH: 'en-US',
  GERMAN: 'de-DE',
  FRENCH: 'fr-FR',
  ITALIAN: 'it-IT',
  SPANISH: 'es-ES'
}
