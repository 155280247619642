/**
 *  1 - version of app is greater then recommended,
 * -1 - version of app is lower then recommended,
 *  0 - versions are same
 * @param version1
 * @param version2
 * @returns
 */
export const compareSemanticVersions = (version1: string, version2: string): number => {
  const version1Components: number[] = version1.split('.').map(Number)
  const version2Components: number[] = version2.split('.').map(Number)

  const maxLength: number = Math.max(version1Components.length, version2Components.length)

  for (let i = 0; i < maxLength; i++) {
    const v1Component: number = i < version1Components.length ? version1Components[i] : 0
    const v2Component: number = i < version2Components.length ? version2Components[i] : 0

    if (v1Component > v2Component) {
      return 1
    } else if (v1Component < v2Component) {
      return -1
    }
  }

  return 0
}
