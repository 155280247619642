import classnames from 'classnames'
import { forwardRef, useEffect, useState } from 'react'

const WIDTH = 320
const MAX_VALUE = 1

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'children'> & {
  value: number
  showPercentage?: boolean
  percentagePosition?: 'right' | 'bellow' | 'tooltip'
  colors?: {
    background?: string
    bar?: string
    barFull?: string
  }
}

/**
 * @group Components
 * @category Component
 */
export const ProgressBar = forwardRef(
  (
    { className, value, showPercentage, percentagePosition = 'right', colors, ...restProps }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [actualValue, setActualValue] = useState<number>(0)
    const backgroundColor = colors?.background ? `bg-[${colors.background}]` : 'bg-grayNeutral-100'
    const barColor = colors?.bar ? `bg-[${colors.bar}]` : 'bg-primary'
    const barFullColor = colors?.bar ? `bg-[${colors.barFull}]` : 'bg-positive'

    useEffect(() => {
      setActualValue(value > MAX_VALUE ? 1 : value)
    }, [value])

    return (
      <div ref={ref} className={classnames('flex flex-row', className)} {...restProps}>
        <div
          className={classnames(
            'flex',
            percentagePosition === 'right' ? 'flex-row items-center' : 'flex-col items-end gap-1'
          )}
        >
          <div className={classnames('flex-1 h-1 w-[320px]')}>
            {showPercentage && percentagePosition === 'tooltip' && (
              <div
                className={classnames(
                  'relative flex shadow-l rounded-lg w-[54px] h-[42] px-3 py-2 self-center justify-center mb-[10px]',
                  "before:block before:absolute before:bottom-[-4px] before:left-1/2 before:transform before:-translate-x-1/2 before:w-[12px] before:h-[12px] before:bg-white before:content-[''] before:rotate-45"
                )}
                style={{ left: `${actualValue * WIDTH - 27}px` }}
              >{`${Math.floor(value * 100)}%`}</div>
            )}
            <div className={classnames('flex-1 h-1 bg-grayNeutral-100 rounded ', backgroundColor)} />
            <div
              className={classnames(
                'absolute mt-[-4px] flex-1 h-1 rounded min-w-[8px] transition-width duration-500 max-w-prose',
                value >= 1 ? barFullColor : barColor
              )}
              style={{ width: `${actualValue * WIDTH}px` }}
            />
          </div>
          {showPercentage && percentagePosition !== 'tooltip' && (
            <label className="ml-3 text-sm text-grayNeutral-900">{`${Math.floor(value * 100)}%`}</label>
          )}
        </div>
      </div>
    )
  }
)
