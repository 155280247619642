import classnames from 'classnames'
import { forwardRef } from 'react'

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'inputMode' | 'size' | 'value'> & {
  disabled?: boolean
  label?: string
  description?: string
}

/**
 * @group Components
 * @category Component
 */
export const Toggle = forwardRef(
  ({ className, children, description, label, disabled, ...restProps }: Props, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div className="flex flex-row  h-6">
        <label className={classnames('relative inline-flex items-center cursor-pointer', className)}>
          <input type="checkbox" className="sr-only peer" ref={ref} disabled={disabled} {...restProps} />
          <div
            className={classnames(
              'bg-grayNeutral-100 rounded-full',
              "after:content-[''] after:absolute after:left-[2px] after:bg-white after:shadow-s after:rounded-full after:h-5 after:w-5 after:transition-all after:top-[2px]",
              'peer-focus:outline-none peer-checked:after:translate-x-full peer-checked:after:border-primary',
              'w-11 h-6 peer-focus-within:w-12 peer-focus-within:h-[28px] peer-focus-within:after:left-[4px] peer-focus-within:border-2 peer-focus-within:border-primary',
              disabled
                ? 'peer-checked:bg-grayNeutral-300 bg-grayNeutral-300 after:bg-grayNeutral-100'
                : 'peer-checked:bg-primary peer-checked:hover:bg-brand-600 peer-checked:hover:border-brand-600 active:w-12 active:h-[28px]'
            )}
          />
        </label>
        {label && (
          <div className={classnames('flex flex-col ml-2 text-sm', description ? 'mt-1' : 'mt-[2px]')}>
            {label && <span className={classnames('text-gray-900', description && 'font-semibold')}>{label}</span>}
            {description && <span className="text-grayNeutral-500">{description}</span>}
          </div>
        )}
      </div>
    )
  }
)
