import dayjs from 'dayjs'

import { DateFormatEnum } from '@app/modules/common/model/enums'

/**
 * Format Date into specific format
 * @param date Date variable to be formatted
 * @param format Optional parameter of desired format
 * @returns Date as string in desired format
 */
export const formatDate = (
  date: string | Date | undefined | null,
  format = DateFormatEnum.DAY_MONTH_YEAR,
  inputFormat?: DateFormatEnum
): string => {
  if (!date) {
    return ''
  }

  return dayjs(date, inputFormat).format(format)
}

/**
 * Format Date from format BE format to FE format
 * @param date Date variable to be formatted as string
 * @returns Date as string in DD.MM.YYYY format
 */
export const convertDateFromBEtoFE = (date: string): string => {
  if (!date) {
    return ''
  }

  return dayjs(date, DateFormatEnum.YEAR_MONTH_DAY).format(DateFormatEnum.DAY_MONTH_YEAR)
}

/**
 * Format Date from format FE format to BE format
 * @param date Date variable to be formatted as string
 * @returns Date as string in DD.MM.YYYY format
 */
export const convertDateFromFEtoBE = (date: string): string => {
  if (!date) {
    return ''
  }

  return dayjs(date, DateFormatEnum.DAY_MONTH_YEAR).format(DateFormatEnum.YEAR_MONTH_DAY)
}
