import LessonEndGangnamStyle from './video/gangnam_style.mov'
import LessonEndGangnamStyleWebm from './video/gangnam_style.webm'
import TaskHomework from './video/task_homework.webm'
import TaskRepetition from './video/task_repetition.webm'
import TaskSelfStudy from './video/task_selfstudy.webm'
import TaskTest from './video/task_test.webm'

export const VideoContent = {
  tasks: {
    homework: TaskHomework,
    repetition: TaskRepetition,
    selfStudy: TaskSelfStudy,
    test: TaskTest
  },

  lesson: {
    gangnamStyle: LessonEndGangnamStyle,
    gangnamStyleWebm: LessonEndGangnamStyleWebm
  }
}
