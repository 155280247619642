import React, { ReactElement, SVGProps } from 'react'

export const CzechFlagIcon = React.forwardRef(
  ({ height = 24, width = 16, ...props }: SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>): ReactElement => {
    return (
      <svg style={props.style} {...props} ref={ref} width={width} height={height} viewBox="0 0 24 16" fill="none">
        <g clipPath="url(#clip0_1780_1387)">
          <g clipPath="url(#clip1_1780_1387)">
            <rect width="24" height="16" fill="#F93939" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0H24V8H0V0Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0L11.4286 8L0 16V0Z" fill="#1A47B8" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1780_1387">
            <rect width="24" height="16" rx="2" fill="white" />
          </clipPath>
          <clipPath id="clip1_1780_1387">
            <rect width="24" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
)

CzechFlagIcon.displayName = 'CzechFlagIcon'
