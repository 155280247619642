import { Suspense, useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { Id, toast } from 'react-toastify'
import { WifiOff } from '@untitled-ui/icons-react'

import { LoaderWrapper, Text } from '@app/components'
import { useTranslation } from '@app/locales'
import AppUrlListener from '@app/modules/auth/providers/AppUrlProvider'
import { InstallAppBanner } from '@app/modules/auth/view/InstallAppBanner/InstallAppBanner'
import { showToast } from '@app/utils/commonUtils'
import { isBrowser, isMobileOrTablet } from '@app/utils/platformUtils'

export const MainLayout = () => {
  const { t } = useTranslation('error')
  const toastId = useRef<Id>()

  useEffect(() => {
    const checkInternetConnection = () => {
      if (!navigator.onLine) {
        toastId.current = showToast(
          <div className="flex items-center gap-1">
            <Text>{t('noConnection')}</Text>
            <WifiOff width={16} height={16} />
          </div>,
          { type: 'error', draggable: true, autoClose: false }
        )
      } else {
        if (toastId.current) {
          toast.dismiss(toastId.current)
        }
      }
    }

    checkInternetConnection()

    window.addEventListener('online', checkInternetConnection)
    window.addEventListener('offline', checkInternetConnection)

    return () => {
      window.removeEventListener('online', checkInternetConnection)
      window.removeEventListener('offline', checkInternetConnection)
    }
  }, [])

  return (
    <Suspense fallback={<LoaderWrapper loading className="h-screen" />}>
      <AppUrlListener />

      <main className="flex h-full w-full justify-center overflow-y-hidden">
        {isMobileOrTablet() && isBrowser() && <InstallAppBanner />}
        <Outlet />
      </main>
    </Suspense>
  )
}
