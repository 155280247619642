import { ReactElement, ReactNode } from 'react'
import { ApolloProvider } from '@apollo/client'

import { initApolloClient } from '../actions/apolloActions'
import { apolloStore } from '../store/apolloStore'

type Props = {
  children: ReactNode | ReactNode[] | null
}

initApolloClient()

export const GraphqlProvider = (props: Props): ReactElement | null => {
  const apolloClient = apolloStore.useStore((store) => store.apolloClient)

  return apolloClient ? <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider> : null
}
