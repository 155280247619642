export enum SupportedAppLanguage {
  ENGLISH = 'ENGLISH',
  CZECH = 'CZECH'
}

export type ResourceLangType =
  | 'classroom'
  | 'common'
  | 'dashboard'
  | 'error'
  | 'learningPath'
  | 'login'
  | 'onboarding'
  | 'settings'
  | 'socialActivities'
  | 'training'
  | 'translation'
  | 'tutorial'

export type AppLanguages = 'cs-CZ' | 'en-US'

export const appLanguageToCode: Record<SupportedAppLanguage, AppLanguages> = {
  [SupportedAppLanguage.CZECH]: 'cs-CZ',
  [SupportedAppLanguage.ENGLISH]: 'en-US'
}

export const languageCodeToSupportedLanguage: Record<AppLanguages, SupportedAppLanguage> = {
  'cs-CZ': SupportedAppLanguage.CZECH,
  'en-US': SupportedAppLanguage.ENGLISH
}

export const languageCodeToShort: Record<string, string> = {
  'cs-CZ': 'cs',
  'en-US': 'en'
}
