import { FlashCardAssignmentType, FlashCardResponseType, FullScreenFlashCardType } from '@app/data'

// Settings is stored in developer settings screen so change flashcards there
export const getSupportedFullscreenFlashCardTypes = (): FullScreenFlashCardType[] => {
  return [
    // FullScreenFlashCardType.SWIPE_MATCH,
    FullScreenFlashCardType.TEXT_TO_IMAGE_CONNECTION,
    FullScreenFlashCardType.TEXT_TO_TEXT_CONNECTION,
    FullScreenFlashCardType.SOUND_TO_TEXT_CONNECTION,
    FullScreenFlashCardType.WHEEL_OF_FORTUNE_IMAGE,
    FullScreenFlashCardType.WHEEL_OF_FORTUNE_TEXT

    /** unsupported types */
    // FlashCardType.SHOPPING_CART
  ]
}

export const getSupportedFlashCardAssignmentTypes = (): FlashCardAssignmentType[] => {
  return [
    FlashCardAssignmentType.IMAGE,
    // FlashCardAssignmentType.SOUND, // this type should not be used anymore
    FlashCardAssignmentType.TEXT,
    FlashCardAssignmentType.VIDEO
  ]
}

export const getSupportedFlashCardResponseTypes = (): FlashCardResponseType[] => {
  return [
    FlashCardResponseType.CHOICE_IMAGE,
    FlashCardResponseType.CHOICE_TEXT,
    FlashCardResponseType.SOUND,
    // FlashCardResponseType.TEXT, // this type should not be used anymore
    FlashCardResponseType.SCRABBLE,
    FlashCardResponseType.WORD_PICKING
  ]
}
