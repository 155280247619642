import { useEffect, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import { StudentRank } from '@app/data'

import { RanksDataType } from '../../../model/enums'
import { NoResults } from './NoResults'
import { PlayerItem } from './PlayerItem'

type RestPlayersListProps = {
  dataType: RanksDataType
  setDataType: (dataType: RanksDataType) => void
  playersForWeek: StudentRank[]
  playersForMonth: StudentRank[]
  playersOverall: StudentRank[]
  onSlideChangeTransitionStart: () => void
  onSlideChangeTransitionEnd: () => void
  onCurrentStudentInWeekView?: (inView: boolean) => void
  onCurrentStudentInMonthView?: (inView: boolean) => void
  onCurrentStudentInOverallView?: (inView: boolean) => void
}

export const RestPlayersList = ({
  dataType,
  setDataType,
  playersForWeek,
  playersForMonth,
  playersOverall,
  onSlideChangeTransitionStart,
  onSlideChangeTransitionEnd,
  onCurrentStudentInWeekView,
  onCurrentStudentInMonthView,
  onCurrentStudentInOverallView
}: RestPlayersListProps) => {
  const [swiper, setSwiper] = useState<SwiperClass>()

  useEffect(() => {
    if (dataType === RanksDataType.WEEK) {
      if (swiper?.activeIndex !== 0) swiper?.slideTo(0)
    } else if (dataType === RanksDataType.MONTH) {
      if (swiper?.activeIndex !== 1) swiper?.slideTo(1)
    } else if (dataType === RanksDataType.OVERALL) {
      if (swiper?.activeIndex !== 2) swiper?.slideTo(2)
    }
  }, [dataType, swiper])

  const handleSlideChange = () => {
    if (swiper?.activeIndex === 0) {
      setDataType(RanksDataType.WEEK)
    } else if (swiper?.activeIndex === 1) {
      setDataType(RanksDataType.MONTH)
    } else if (swiper?.activeIndex === 2) {
      setDataType(RanksDataType.OVERALL)
    }
  }

  return (
    <Swiper
      onSlideChangeTransitionStart={onSlideChangeTransitionStart}
      onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
      onSlideChange={handleSlideChange}
      onSwiper={(swiper) => setSwiper(swiper)}
      preventClicks={true}
      preventClicksPropagation={true}
      preventInteractionOnTransition={true}
    >
      <SwiperSlide>
        {playersForWeek.length === 0 ? (
          <NoResults />
        ) : (
          playersForWeek.map((player, index) => {
            return (
              <PlayerItem
                key={player.id}
                player={player}
                index={index}
                onCurrentStudentInView={player.currentStudent ? onCurrentStudentInWeekView : undefined}
              />
            )
          })
        )}
      </SwiperSlide>

      <SwiperSlide>
        {playersForMonth.length === 0 ? (
          <NoResults />
        ) : (
          playersForMonth.map((player, index) => {
            return (
              <PlayerItem
                key={player.id}
                player={player}
                index={index}
                onCurrentStudentInView={player.currentStudent ? onCurrentStudentInMonthView : undefined}
              />
            )
          })
        )}
      </SwiperSlide>

      <SwiperSlide>
        {playersOverall.length === 0 ? (
          <NoResults />
        ) : (
          playersOverall.map((player, index) => {
            return (
              <PlayerItem
                key={player.id}
                player={player}
                index={index}
                onCurrentStudentInView={player.currentStudent ? onCurrentStudentInOverallView : undefined}
              />
            )
          })
        )}
      </SwiperSlide>
    </Swiper>
  )
}
