import { HTMLAttributes } from 'react'
import { ChevronLeft } from '@untitled-ui/icons-react'

export const GoBackBtn = ({ onClick }: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className="h-12 w-12 rounded-full bg-btn-primary-outline-bg-gradient p-3 shadow-card" onClick={onClick}>
      <ChevronLeft className="text-[#52287C]" />
    </button>
  )
}
