import { NetworkStatus, QueryHookOptions } from '@apollo/client'

import { GetActiveTasksQuery, GetActiveTasksQueryVariables, useGetActiveTasksQuery } from '@app/data'

export const useActiveTasks = (baseOptions?: QueryHookOptions<GetActiveTasksQuery, GetActiveTasksQueryVariables>) => {
  const query = useGetActiveTasksQuery({
    ...baseOptions,
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: 'overwrite',
    nextFetchPolicy: 'network-only'
  })

  return {
    refetch: query.refetch,
    refetching: query.networkStatus === NetworkStatus.refetch,
    loading: query.loading,
    error: query.error,
    activeTasks: query.data?.tasks
  }
}
