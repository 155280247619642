import classnames from 'classnames'
import { forwardRef } from 'react'

type VariantType = 'outlined' | 'simple'

type Props = React.HTMLProps<HTMLTextAreaElement> & {
  disabled?: boolean
  error?: boolean
  label?: string
  description?: string
  errorText?: string
  variant?: VariantType
  textareaClassName?: string
}

const validClasses = 'bg-white border-grayNeutral-300 placeholder:text-grayNeutral-500 focus-within:border-primary'
const errorClasses = 'bg-white border-negative placeholder:text-grayNeutral-500 focus-within:border-negative'
const disabledClasses = 'bg-grayNeutral-50 text-grayNeutral-400 border-grayNeutral-200 placeholder:text-grayNeutral-500'

const variantToStyle: Record<VariantType, string> = {
  outlined: 'px-[14px] py-[10px] border-[1px] focus-within:border-2',
  simple: ''
}

/**
 * @group Components
 * @category Component
 */
export const TextArea = forwardRef(
  (
    {
      className,
      children,
      disabled,
      description,
      error,
      errorText,
      label,
      inputMode = 'text',
      variant = 'outlined',
      textareaClassName,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const hasError = !!errorText || error
    const variantStyle = variantToStyle[variant]

    return (
      <div className={classnames('flex flex-col gap-2 w-full', className)}>
        {label ? <label className="text-sm text-grayNeutral-900">{label}</label> : null}
        <div
          className={classnames(
            'text-sm flex flex-row gap-1 rounded-lg items-center w-full',
            disabled ? disabledClasses : hasError ? errorClasses : validClasses,
            variantStyle,
            ''
          )}
        >
          <textarea
            ref={ref}
            inputMode={'text'}
            disabled={disabled}
            rows={4}
            className={classnames(
              'flex-1 items-center resize-none focus:outline-none rounded',
              disabled ? 'bg-grayNeutral-50' : 'bg-white',
              textareaClassName
            )}
            {...restProps}
          />
        </div>
        {errorText && !disabled ? (
          <p className="text-sm text-negative">{errorText}</p>
        ) : description ? (
          <p className="text-sm text-grayNeutral-500">{description}</p>
        ) : null}
      </div>
    )
  }
)
