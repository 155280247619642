import { colors } from '@foxino/configs/tailwind/colors'
import classnames from 'classnames'
import { forwardRef } from 'react'

type SizeType = 'small' | 'big'

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'inputMode' | 'size' | 'value' | 'onChange'> & {
  label?: string
  description?: string
  size?: SizeType
  indeterminate?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

/**
 * @group Components
 * @category Component
 */
export const RadioButton = forwardRef(
  (
    {
      className,
      children,
      description,
      label,
      indeterminate,
      disabled,
      name = 'radio-group',
      onChange,
      size = 'small',
      ...restProps
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      event.stopPropagation()
      onChange?.(event)
    }

    return (
      <div className={classnames('flex', className)}>
        <input
          id={`inline-radio/${label}`}
          name={name}
          type="radio"
          onChange={handleOnChange}
          disabled={disabled}
          className={classnames(
            'appearance-none peer rounded-full border-[1px] border-grayNeutral-300',
            size === 'small' ? 'w-4 h-4 mt-[-1px] ml-[-1px]' : 'w-5 h-5  mt-[-2px] ml-[-2px]',
            !disabled &&
              'hover:border-brand-600 hover:bg-brand-25 hover:checked:bg-brand-600 active:border-[2px] active:bg-white checked:bg-primary checked:border-primary',
            disabled
              ? 'cursor-not-allowed bg-grayNeutral-100 border-grayNeutral-200 checked:bg-grayNeutral-100 checked:border-grayNeutral-200'
              : 'cursor-pointer'
          )}
          ref={ref}
          {...restProps}
        />
        {label && (
          <div className={classnames('flex flex-col ml-2 mt-[-3px] text-sm')}>
            {label && (
              <label
                htmlFor={`inline-radio/${label}`}
                className={classnames(
                  !disabled ? 'text-grayNeutral-900' : 'text-grayNeutral-400',
                  description && 'font-semibold'
                )}
              >
                {label}
              </label>
            )}
            {description && <span className="text-grayNeutral-500">{description}</span>}
          </div>
        )}
        <svg
          className="absolute hidden mt-1 ml-1 pointer-events-none peer-checked:block"
          width={size === 'small' ? 6 : 8}
          height={size === 'small' ? 6 : 8}
          viewBox="0 0 6 6"
          fill="none"
        >
          <circle cx="3" cy="3" r="3" fill={disabled ? colors.grayNeutral[200] : 'white'} />
        </svg>
      </div>
    )
  }
)
