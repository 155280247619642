import {
  GetActiveTasksDocument,
  GetFinishedTasksDocument,
  GetStudentInfoDocument,
  useAddMeToClassroomMutation,
  useAddUserRoleMutation,
  UserRole
} from '@app/data'
import { useTranslation } from '@app/locales'
import { showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

export const useAddRoleAndClassroom = () => {
  const { t } = useTranslation('common')

  const [addUserRoleMutation, addUserRoleMutationData] = useAddUserRoleMutation()
  const [addMeToClassroomMutation, addMeToClassroomMutationData] = useAddMeToClassroomMutation()

  const pending = addUserRoleMutationData.loading || addMeToClassroomMutationData.loading
  const error = addUserRoleMutationData.error || addMeToClassroomMutationData.error

  const addUserRole = async (userRole: UserRole) => {
    try {
      return await addUserRoleMutation({
        variables: { userRole }
      })
    } catch (err) {
      logError(err, 'useUserRole', 'addUserRole')
      showToast(t('errorGlobal'), { type: 'error' })
    }
  }

  const addMeToClassroom = async (classroomId: string, classroomCode: string) => {
    try {
      const { data } = await addMeToClassroomMutation({
        variables: { classroomId, classroomCode },
        refetchQueries: [GetActiveTasksDocument, GetFinishedTasksDocument, GetStudentInfoDocument]
      })

      return data?.addMeToClassroom
    } catch (err) {
      logError(err, 'useUserRole', 'addMeToClassroom')
      showToast(t('errorGlobal'), { type: 'error' })
    }
  }

  return { pending, error, addUserRole, addMeToClassroom }
}
