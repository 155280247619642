import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'

export const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate()

  const appListener = useRef<PluginListenerHandle | null>(null)

  useEffect(() => {
    const initialize = async () => {
      appListener.current = await App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
        const url = new URL(event.url)

        const slug = url.pathname.concat(url.search).concat(url.hash)

        if (slug) {
          window.location.href = slug
        } else {
          navigate('/')
        }
      })
    }

    initialize()

    // Cleanup the listener on component unmount
    return () => {
      appListener.current?.remove()
    }
  }, [navigate])

  return null
}

export default AppUrlListener
