import { forwardRef } from 'react'
import { Label } from '../Typography/Label'
import {} from '@untitled-ui/icons-react'
import classNames from 'classnames'
import { WarningIcon } from './WarningIcon'
import { ReadyIcon } from './ReadyIcon'

type SizeType = 'small' | 'big'

export type VariantType =
  | 'blue'
  | 'gray'
  | 'blueGray'
  | 'blueLight'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'rose'
  | 'orange'
  | 'green'

const variantToStyle: Record<VariantType, string> = {
  blue: 'bg-accentBlueLightest hover:bg-blue-200 active:bg-blue-400',
  gray: 'bg-grayNeutral-100 hover:bg-grayNeutral-150 active:bg-grayNeutral-400',
  blueGray: 'bg-accentBlueGrayLightest hover:bg-grayBlue-200 active:bg-grayBlue-400',
  blueLight: 'bg-accentBlueLightLightest hover:bg-blueLight-200 active:bg-blueLight-400',
  indigo: 'bg-accentIndigoLightest hover:bg-indigo-200 active:bg-indigo-400',
  purple: 'bg-accentPurpleLightest hover:bg-purple-200 active:bg-purple-400',
  pink: 'bg-accentPinkLightest hover:bg-pink-200 active:bg-pink-400',
  rose: 'bg-accentRoseLightest hover:bg-rose-200 active:bg-rose-400',
  orange: 'bg-accentOrangeLightest hover:bg-darkOrange-200 active:bg-darkOrange-300',
  green: 'bg-greenLight-100 hover:bg-greenLight-200 active:bg-greenLight-400'
}

const variantToDashStyle: Record<VariantType, string> = {
  blue: 'bg-accentBlueLight',
  gray: 'bg-grayNeutral-400',
  blueGray: 'bg-grayBlue-400',
  blueLight: 'bg-blueLight-400',
  indigo: 'bg-accentIndigoLight',
  purple: 'bg-accentPurpleLight',
  pink: 'bg-accentPinkLight',
  rose: 'bg-accentRoseLight',
  orange: 'bg-darkOrange-300',
  green: 'bg-greenLight-300'
}

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'size'> & {
  label: string
  description?: string
  size?: SizeType
  variant?: VariantType
  icon?: React.ReactNode
  warningText?: string
  readyText?: string
}

/**
 * @group Components
 * @category Component
 */
export const Event = forwardRef(
  (
    {
      className,
      onClick,
      size = 'big',
      variant = 'blue',
      disabled,
      label,
      description,
      icon,
      warningText,
      readyText,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const dashStyle = variantToDashStyle[variant]
    const variantStyle = variantToStyle[variant]

    return (
      <div
        ref={ref}
        className={classNames(
          'group',
          variantStyle,
          onClick && 'hover:cursor-pointer',
          size === 'small' ? 'h-7' : ' h-full',
          'w-full rounded-md flex flex-row gap-2 p-1 active:text-white'
        )}
        {...restProps}
      >
        <div className={classNames(dashStyle, 'w-[3px] rounded h-full group-active:bg-white')} />
        <div className="flex flex-col flex-1 h-full truncate">
          <div className="flex flex-row items-center w-full">
            <Label size="m" weight="bold" className="w-full truncate" children={label} />
            {warningText && size === 'small' && <WarningIcon className="group-active:text-white" />}
          </div>
          {size === 'big' && (
            <div>
              <Label
                size="s"
                weight="medium"
                className="truncate peer-hover:bg-grayNeutral-600"
                children={description}
              />
              {icon}
              {warningText && (
                <div className="flex flex-row items-center w-full gap-1 truncate">
                  <WarningIcon className="group-active:text-white min-w-[13px]" />
                  <Label
                    size="s"
                    weight="semibold"
                    className="text-negative group-active:text-white"
                    children={warningText}
                  />
                </div>
              )}
              {readyText && (
                <div className="flex flex-row items-center gap-1 truncate">
                  <ReadyIcon className="group-active:text-white min-w-[13px]" />
                  <Label className="truncate" size="s" weight="semibold" children={readyText} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
)
