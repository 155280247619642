import { Haptics, ImpactStyle } from '@capacitor/haptics'

export const hapticsImpactHeavy = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Heavy })
  } catch {
    // do nothing
  }
}

export const hapticsImpactMedium = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Medium })
  } catch {
    // do nothing
  }
}

export const hapticsImpactLight = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Light })
  } catch {
    // do nothing
  }
}

export const hapticsVibrate = async () => {
  try {
    await Haptics.vibrate()
  } catch {
    // do nothing
  }
}

export const hapticsSelectionStart = async () => {
  try {
    await Haptics.selectionStart()
  } catch {
    // do nothing
  }
}

export const hapticsSelectionChanged = async () => {
  try {
    await Haptics.selectionChanged()
  } catch {
    // do nothing
  }
}

export const hapticsSelectionEnd = async () => {
  try {
    await Haptics.selectionEnd()
  } catch {
    // do nothing
  }
}
