import { ReactNode } from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { CollisionDetection, rectIntersection } from '@dnd-kit/core'

/**
 * Show notifications
 */
export const showToast = (text: ReactNode, options?: ToastOptions) => {
  return toast(text, { type: 'success', ...options })
}

export const isCurrentPathActive = (pathname: string, path: string, isExact?: boolean) => {
  const pathRegex = new RegExp(path, 'i')

  if (isExact) {
    return path === pathname
  } else {
    return pathRegex.test(pathname)
  }
}

/**
 * solution from https://github.com/clauderic/dnd-kit/pull/334#issuecomment-1965708784
 */
export const dndFixCursorSnapOffset: CollisionDetection = (args) => {
  // Bail out if keyboard activated
  if (!args.pointerCoordinates) {
    return rectIntersection(args)
  }

  const { x, y } = args.pointerCoordinates
  const { width, height } = args.collisionRect
  const updated = {
    ...args,
    // The collision rectangle is broken when using snapCenterToCursor
    // Reset the collision rectangle based on pointer location and overlay size.
    collisionRect: {
      width,
      height,
      bottom: y + height / 2,
      left: x - width / 2,
      right: x + width / 2,
      top: y - height / 2
    }
  }

  return rectIntersection(updated)
}
