import { useVisibleState } from '@foxino/components-common'
import { ChevronDown } from '@untitled-ui/icons-react'
import classNames from 'classnames'
import cs from 'date-fns/locale/cs'
import en from 'date-fns/locale/en-US'
import React from 'react'
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'

registerLocale('cs-CZ', cs)
registerLocale('en-US', en)

type Props = ReactDatePickerProps & {
  datePickerClassName?: string
}

/**
 * @group Components
 * @category Props
 */
export const DatePickerInput = ({ className, datePickerClassName, onChange, ...restProps }: Props) => {
  const [calendarVisible, showCalendar, hideCalendar] = useVisibleState()

  const handleOnChange = (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => {
    restProps.shouldCloseOnSelect && hideCalendar()
    onChange(date, event)
  }

  return (
    <div
      onClick={showCalendar}
      className={classNames(
        'flex flex-row justify-between text-sm border-[1px] border-grayNeutral-300 rounded-lg px-3 py-2 items-center',
        className
      )}
    >
      <DatePicker
        onChange={handleOnChange}
        className={classNames('outline-none w-[calc(107%)]', datePickerClassName)}
        open={calendarVisible}
        onInputClick={showCalendar}
        onClickOutside={hideCalendar}
        {...restProps}
      />
      <ChevronDown className="text-grayNeutral-500" onClick={showCalendar} />
    </div>
  )
}
