import { useTranslation } from '@app/locales'

export const NoResults = () => {
  const { t } = useTranslation('dashboard')

  return (
    <div className="flex h-52 w-full flex-col gap-5 p-4">
      <div className="text-center text-[20px] font-bold text-[#5B6C9B]">{t('leaderBoard.noResults')}</div>
      <div className="text-center font-light text-[#5B6C9B]/80">{t('leaderBoard.noResultsDescription')}</div>
    </div>
  )
}
