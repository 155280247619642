import classNames from 'classnames'
import { forwardRef } from 'react'

export type DifficultyType = 'low' | 'middle' | 'high' | string

type Props = React.HTMLProps<HTMLDivElement> & {
  variant: DifficultyType
}

/**
 * @group Components
 * @category Component
 */
export const Difficulty = forwardRef(({ className, variant, ...restProps }: Props, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div className={classNames('w-fit flex flex-col gap-[2px]', className)} ref={ref} {...restProps}>
      <span
        className={classNames(
          'w-1 h-1 aspect-square rounded-full',
          variant === 'high' ? 'bg-negative' : 'bg-grayNeutral-150'
        )}
      />
      <span
        className={classNames(
          'w-1 h-1 aspect-square rounded-full',
          variant === 'high' ? 'bg-negative' : variant === 'middle' ? 'bg-warningBase' : 'bg-grayNeutral-150'
        )}
      />
      <span
        className={classNames(
          'w-1 h-1 aspect-square rounded-full',
          variant === 'high' ? 'bg-negative' : variant === 'middle' ? 'bg-warningBase' : 'bg-positive'
        )}
      />
    </div>
  )
})
