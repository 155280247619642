import * as RadixAlertDialog from '@radix-ui/react-alert-dialog'
import { XClose } from '@untitled-ui/icons-react'
import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'

import { Divider, Heading } from '..'

type Props = React.HTMLProps<HTMLDivElement> & {
  title: string
  description?: string
  actionButtons?: ReactNode
  isOpen?: boolean
  onAbort?: () => void
}

/**
 * @group Components
 * @category Component
 */
export const AlertDialog = forwardRef(
  (
    { className, title, description, disabled, children, actionButtons, isOpen, onAbort, ...restProps }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <RadixAlertDialog.Root open={isOpen}>
        {children && (
          <RadixAlertDialog.Trigger asChild disabled={disabled} onAbort={onAbort}>
            <div>{children}</div>
          </RadixAlertDialog.Trigger>
        )}
        <RadixAlertDialog.Portal>
          <RadixAlertDialog.Overlay className="fixed inset-0 z-20 opacity-50 bg-grayNeutral-200" />
          <RadixAlertDialog.Content
            {...restProps}
            ref={ref}
            className={classNames(
              'z-30 data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] focus:outline-none',
              className
            )}
          >
            <div>
              <div className="flex justify-between">
                <RadixAlertDialog.Title className="m-0 text-[17px] font-medium" asChild>
                  <div className="flex justify-between w-full">
                    <Heading size="s" weight="bold">
                      {title}
                    </Heading>
                    <RadixAlertDialog.Action asChild className="cursor-pointer">
                      <XClose onClick={onAbort} />
                    </RadixAlertDialog.Action>
                  </div>
                </RadixAlertDialog.Title>
              </div>
              <Divider className="px-[-15px]" />
              {description && (
                <RadixAlertDialog.Description className="mt-4 mb-5 text-sm ">
                  {description}
                </RadixAlertDialog.Description>
              )}

              <RadixAlertDialog.Action asChild className="m-auto">
                {actionButtons}
              </RadixAlertDialog.Action>
            </div>
          </RadixAlertDialog.Content>
        </RadixAlertDialog.Portal>
      </RadixAlertDialog.Root>
    )
  }
)
