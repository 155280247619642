import { ImageContent } from '@app/assets/ImageContent'
import { Button, Heading, Text } from '@app/components'
import { useTranslation } from '@app/locales'
import { useStudentAppSupportedVersion } from '@app/modules/auth/data/useStudentAppSupportedVersion'

export const InsufficientAppVersion = () => {
  const { t } = useTranslation(['error', 'common'])
  const { openAppStore } = useStudentAppSupportedVersion()

  return (
    <div className="flex h-screen flex-col justify-center px-2 text-neutral100">
      <div className="-mt-12 flex flex-col items-center gap-2">
        <img src={ImageContent.common.versionUpdate} />
        <Heading>{t('version.insufficientTitle')}</Heading>
        <Text className="text-center">{t('version.insufficientDescription')}</Text>
      </div>
      <button onClick={openAppStore} className="mx-auto mt-8 w-full">
        <Button className="mx-auto max-w-[284px]">{t('version.update')}</Button>
      </button>
    </div>
  )
}
