import { useEffect, useRef } from 'react'
import { useIntersection } from 'react-use'
import { motion, useInView } from 'framer-motion'

import { profilePicturesContent } from '@foxino/components-common'

import { StudentRank } from '@app/data'
import { useTranslation } from '@app/locales'
import { getFullName } from '@app/modules/auth/utils/getFullName'
import { useDashboardMenuHeight } from '@app/modules/common/layout/DashboardLayout/useDashboardMenuHeight'

export type PlayerItemProps = {
  player: StudentRank
  index: number
  onCurrentStudentInView?: (inView: boolean) => void
}

export const PLAYER_ITEM_HEIGHT = 56

export const PlayerItem = ({ player, index, onCurrentStudentInView }: PlayerItemProps) => {
  const { t } = useTranslation('common')

  const ref = useRef(null)

  const bottomBarHeight = useDashboardMenuHeight()
  const isInView = useInView(ref, {
    margin: `${PLAYER_ITEM_HEIGHT}px 0px ${-PLAYER_ITEM_HEIGHT}px 0px`,
    once: true
  })

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: `${bottomBarHeight}px 0px ${-bottomBarHeight}px 0px`,
    threshold: 1
  })

  useEffect(() => {
    if (onCurrentStudentInView) {
      onCurrentStudentInView(intersection && intersection.intersectionRatio < 1 ? false : true)
    }
  }, [intersection])

  const { backgroundColor, image } = profilePicturesContent[player.student.userProfile.pictureUrl || 'image1']
  const rowBg = player.currentStudent ? 'linear-gradient(91deg, #D3C3FF 0%, #CEBDFF 100%)' : 'transparent'
  const textStyle = player.currentStudent ? 'text-[#7164E9]' : 'text-[#5B6C9B]'

  return (
    <motion.div
      ref={ref}
      style={{ background: rowBg, height: PLAYER_ITEM_HEIGHT }}
      className={`flex w-full flex-row justify-between rounded-2xl px-2 text-right text-base font-extrabold antialiased ${textStyle}`}
      initial="hidden"
      animate={isInView ? 'visible' : 'hidden'}
      variants={itemVariants}
      transition={{ duration: 0.25 }}
    >
      <div className="flex flex-row items-center gap-2">
        <span className="w-[24px]">{index + 4}.</span>
        <div className="h-10 w-10 rounded-full p-1" style={{ backgroundColor }}>
          <img className="pointer-events-none h-full w-full" src={image} />
        </div>
        <span>{getFullName(player.student.userProfile.firstName, player.student.userProfile.lastName)}</span>
      </div>

      <div className="flex flex-row items-center justify-center gap-1 text-lg">
        {(player.points || 0).toLocaleString()} {t('point')}
      </div>
    </motion.div>
  )
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}
