import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { Loader } from '@foxino/components-web'

import { LoaderWrapper, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { MainHeader } from '@app/modules/common'
import { logError } from '@app/utils/logsUtils'
import { isNative } from '@app/utils/platformUtils'

import { TaskCardSmall } from '../components'
import { useFinishedTasksLazy } from '../data'
import { LearningExecutionType } from '../model/types'

export const FinishedTasksPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'dashboard'])

  const { finishedTasks, error, loading, actions, hasMore } = useFinishedTasksLazy(true)

  useEffect(() => {
    if (error) {
      logError(error, 'FinishedTasksPage', 'useEffect')
    }
  }, [error])

  const handleOnFinishedTaskClick = ({ id }: LearningExecutionType, type: string) => {
    navigate(Pages.DASHBOARD_TASKS_DETAIL(id, type))
  }

  const handleOnShowMoreClick = () => {
    actions.fetchMoreTasks()
  }

  return (
    <div
      style={{ paddingTop: 'env(safe-area-inset-top)' }}
      className="relative flex h-full w-full flex-col overflow-y-auto overscroll-y-contain"
    >
      <MainHeader isBackBtnVisible title={t('activities.finishedTasks')} />

      <LoaderWrapper loading={loading} error={error != null} showChildrenOnLoading={!!finishedTasks}>
        <div className="flex h-auto min-h-[300px] w-full flex-col pt-16">
          <motion.div
            className="flex h-auto flex-col gap-8 p-4 md:p-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {finishedTasks && finishedTasks.length > 0 && (
              <div className="mb-16 flex w-full flex-wrap gap-4">
                <div className="flex w-full flex-wrap justify-center gap-4 md:grid md:grid-cols-2 xl:grid-cols-3">
                  {finishedTasks.map((task, index) => {
                    return (
                      <TaskCardSmall
                        key={task.id}
                        isOdd={index % 2 !== 0}
                        task={task}
                        onFinishedTaskClick={handleOnFinishedTaskClick}
                      />
                    )
                  })}
                </div>
              </div>
            )}

            {hasMore && (
              <div className="mx-auto flex flex-col items-center justify-center">
                {loading ? (
                  <Loader size="xs" className="fill-white0" />
                ) : (
                  <Text
                    size="m"
                    className={classNames(
                      'mt-6 cursor-pointer text-neutral100',
                      loading && 'cursor-not-allowed opacity-55'
                    )}
                    onClick={loading ? undefined : handleOnShowMoreClick}
                  >
                    {t('showMore')}
                  </Text>
                )}
              </div>
            )}

            {/* NOTE: this a workaround for overflow-hidden style in the body element */}
            {isNative() ? <div className="min-h-16 w-full" /> : <div className="min-h-32 w-full" />}
          </motion.div>
        </div>
      </LoaderWrapper>
    </div>
  )
}
