import { useState } from 'react'
import axios, { AxiosError } from 'axios'

import Constants from '@app/constants'
import { useTranslation } from '@app/locales'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { useStudentInfo } from '@app/modules/tasks/data'
import { showToast } from '@app/utils/commonUtils'
import { convertDateFromFEtoBE } from '@app/utils/dateTimeUtils'
import { logError } from '@app/utils/logsUtils'

import {
  GetStudentInfoDocument,
  InteractionType,
  Language,
  useUpdateAppSettingsMutation,
  useUpdateUserProfileMutation
} from '../../../data'

const INCORRECT_PASSWORD = 403

export const useProfileSettings = () => {
  const { t } = useTranslation('common')
  const [sendingPassword, setSendingPassword] = useState(false)

  const { studentInfo, loading: loadingStudent, refetching: refetchingStudent, error } = useStudentInfo()
  const [saveUserProfileSettings, { loading: sending }] = useUpdateUserProfileMutation()
  const [updateAppSettingsMutation, updateAppSettingsMutationData] = useUpdateAppSettingsMutation()
  const interactionTypes = lessonInputsStore.useStore((state) => state.nextFlashCardInput.interactionTypes)

  const loading = sending || loadingStudent || sendingPassword || updateAppSettingsMutationData.loading
  const canSpeak = interactionTypes.includes(InteractionType.SPEAKING)
  const canListen = interactionTypes.includes(InteractionType.LISTENING)

  const saveAppLanguage = async ({ appLanguage }: { appLanguage?: Language }): Promise<boolean> => {
    try {
      await updateAppSettingsMutation({
        variables: { applicationSettings: { appLanguage } },
        refetchQueries: [GetStudentInfoDocument]
      })

      return true
    } catch (err) {
      logError(err, 'useProfileSettings', 'saveAppLanguage')
      showToast(t('somethingWentWrong'), { type: 'error' })

      return false
    }
  }

  const saveUserProfile = async ({
    firstName,
    lastName,
    birthday,
    pictureId
  }: {
    firstName?: string
    lastName?: string
    birthday?: string
    pictureId?: string
  }): Promise<boolean> => {
    try {
      await saveUserProfileSettings({
        variables: {
          userProfile: {
            birthday: birthday ? convertDateFromFEtoBE(birthday) : undefined,
            firstName,
            lastName,
            pictureId
          }
        },
        refetchQueries: [GetStudentInfoDocument]
      })
      return true
    } catch (err) {
      logError(err, 'useProfileSettings', 'saveUserProfile')
      showToast(t('somethingWentWrong'), { type: 'error' })
      return false
    }
  }

  const saveNewPassword = async (oldPassword: string, newPassword: string) => {
    setSendingPassword(true)
    try {
      await axios.put(Constants.RESET_PASSWORD_ENDPOINT, {
        oldPassword,
        newPassword
      })

      return true
    } catch (err) {
      if (err instanceof AxiosError) {
        // FIX: status code is undefined from axios, repair axios version
        if (err.message.includes(INCORRECT_PASSWORD.toString())) {
          showToast(t('changePassword.wrongPassword'), { type: 'error' })
        }
      } else {
        showToast(t('somethingWentWrong'), { type: 'error' })
      }

      logError(err, 'useProfileSettings', 'saveNewPassword')
      return false
    } finally {
      setSendingPassword(false)
    }
  }

  const onToggleInteractionsValue = (type: InteractionType) => {
    let newCanSpeak = canSpeak
    let newCanListen = canListen

    if (type === InteractionType.SPEAKING) {
      newCanSpeak = !canSpeak
    }

    if (type === InteractionType.LISTENING) {
      newCanListen = !canListen
    }

    lessonInputsStore.setInteractionTypes(newCanSpeak, newCanListen)
  }

  return {
    saveAppLanguage,
    saveUserProfile,
    saveNewPassword,
    onToggleInteractionsValue,
    canSpeak,
    canListen,
    studentProfile: studentInfo?.student,
    refetching: refetchingStudent,
    loading,
    error
  }
}
