/**
 * Calculates the age from Date
 * @param birthday Birthday as Date of ISOstring
 * @returns Calculated age from the Date
 */
export const calculateAge = (birthday: string | Date | undefined): number => {
  let birthdate = birthday

  if (birthdate === undefined) {
    return NaN
  }

  if (typeof birthdate === 'string') {
    birthdate = new Date(birthdate)
  }

  const now = new Date()
  let age = now.getFullYear() - birthdate.getFullYear()
  const monthDiff = now.getMonth() - birthdate.getMonth()

  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthdate.getDate())) {
    age--
  }

  return age
}

/**
 * Return random int from 0 to defined max number
 * @param max Max possible number
 * @returns Random number
 */
export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max)
}
