import { PropsWithChildren, Suspense } from 'react'

export const SettingsLayout = ({ children }: PropsWithChildren) => {
  return (
    <Suspense>
      <div
        style={{ paddingTop: 'env(safe-area-inset-top)' }}
        className="relative flex h-screen w-full max-w-[1312px] flex-col items-center overflow-y-auto pb-40"
      >
        {children}
      </div>
    </Suspense>
  )
}
