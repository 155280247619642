import { NetworkStatus, QueryHookOptions } from '@apollo/client'

import { GetStudentRanksQuery, GetStudentRanksQueryVariables, useGetStudentRanksQuery } from '@app/data'

export const useStudentsRanks = (
  classroomId?: string,
  baseOptions?: QueryHookOptions<GetStudentRanksQuery, GetStudentRanksQueryVariables>
) => {
  const { data, ...restOfData } = useGetStudentRanksQuery({
    variables: { classroomId: classroomId || '' },
    skip: !classroomId,
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: 'overwrite',
    nextFetchPolicy: 'cache-and-network',
    ...baseOptions
  })

  return {
    weekRanks: data?.weekRanks || [],
    monthRanks: data?.monthRanks || [],
    overallRanks: data?.overallRanks || [],
    refetching: restOfData.networkStatus === NetworkStatus.refetch,
    ...restOfData
  }
}
