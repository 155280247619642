import { SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

export const WarningIcon = ({ color = '#F7483B', ...restProps }: Props): JSX.Element => {
  return (
    <svg
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66 11.5275C4.39 11.8425 5.17 12 6 12C6.83 12 7.61 11.8425 8.34 11.5275C9.07 11.2125 9.705 10.785 10.245 10.245C10.785 9.705 11.2125 9.07 11.5275 8.34C11.8425 7.61 12 6.83 12 6C12 5.17 11.8425 4.39 11.5275 3.66C11.2125 2.93 10.785 2.295 10.245 1.755C9.705 1.215 9.07 0.7875 8.34 0.4725C7.61 0.1575 6.83 0 6 0C5.17 0 4.39 0.1575 3.66 0.4725C2.93 0.7875 2.295 1.215 1.755 1.755C1.215 2.295 0.7875 2.93 0.4725 3.66C0.1575 4.39 0 5.17 0 6C0 6.83 0.1575 7.61 0.4725 8.34C0.7875 9.07 1.215 9.705 1.755 10.245C2.295 10.785 2.93 11.2125 3.66 11.5275ZM6 7.5C6.28333 7.5 6.52083 7.40417 6.7125 7.2125C6.90417 7.02083 7 6.78333 7 6.5V2.5C7 2.21667 6.90417 1.97917 6.7125 1.7875C6.52083 1.59583 6.28333 1.5 6 1.5C5.71667 1.5 5.47917 1.59583 5.2875 1.7875C5.09583 1.97917 5 2.21667 5 2.5V6.5C5 6.78333 5.09583 7.02083 5.2875 7.2125C5.47917 7.40417 5.71667 7.5 6 7.5ZM6 10.5C6.28333 10.5 6.52083 10.4042 6.7125 10.2125C6.90417 10.0208 7 9.78333 7 9.5C7 9.21667 6.90417 8.97917 6.7125 8.7875C6.52083 8.59583 6.28333 8.5 6 8.5C5.71667 8.5 5.47917 8.59583 5.2875 8.7875C5.09583 8.97917 5 9.21667 5 9.5C5 9.78333 5.09583 10.0208 5.2875 10.2125C5.47917 10.4042 5.71667 10.5 6 10.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
