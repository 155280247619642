import { Preferences } from '@capacitor/preferences'
import { StateStorage } from 'zustand/middleware'

import { isNative } from './platformUtils'

export const nativeStorage: () => StateStorage = () => {
  if (!isNative()) {
    return localStorage
  } else {
    return {
      getItem: async (key: string): Promise<string | null> => {
        const result = await Preferences.get({ key })
        return result.value
      },
      setItem: async (key: string, value: string): Promise<void> => {
        await Preferences.set({ key, value })
      },
      removeItem: async (key: string): Promise<void> => {
        await Preferences.remove({ key })
      }
    }
  }
}
