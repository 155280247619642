import { CzechFlagIcon } from '@app/components/FlagIcons/flat/CzechFlagIcon'
import { EnglishFlagIcon } from '@app/components/FlagIcons/flat/EnglishFlagIcon'
import { CzechFlagRoundIcon } from '@app/components/FlagIcons/round/CzechFlagRoundIcon'
import { EnglishFlagRoundIcon } from '@app/components/FlagIcons/round/EnglishFlagRoundIcon'
import { FranceFlagRoundIcon } from '@app/components/FlagIcons/round/FranceFlagRoundIcon'
import { GermanFlagRoundIcon } from '@app/components/FlagIcons/round/GermanFlagRoundIcon'
import { ItalyFlagRoundIcon } from '@app/components/FlagIcons/round/ItalyFlagRoundIcon'
import { SpanishFlagRoundIcon } from '@app/components/FlagIcons/round/SpanishFlagRoundIcon'
import { Language, Subject } from '@app/data'

export const languageToFlagRoundIcon: Record<Language, typeof CzechFlagRoundIcon> = {
  CZECH: CzechFlagRoundIcon,
  ENGLISH: EnglishFlagRoundIcon,
  FRENCH: FranceFlagRoundIcon,
  GERMAN: GermanFlagRoundIcon,
  ITALIAN: ItalyFlagRoundIcon,
  SPANISH: SpanishFlagRoundIcon
}

export const subjectToFlagRoundIcon: Record<Subject, typeof CzechFlagRoundIcon> = {
  ENGLISH: EnglishFlagRoundIcon,
  CZECH: CzechFlagRoundIcon,
  FRENCH: FranceFlagRoundIcon,
  GERMAN: GermanFlagRoundIcon,
  ITALIAN: ItalyFlagRoundIcon,
  SPANISH: SpanishFlagRoundIcon
}

export const languageToFlagIcon: Record<Language, typeof CzechFlagRoundIcon> = {
  CZECH: CzechFlagIcon,
  ENGLISH: EnglishFlagIcon,
  FRENCH: FranceFlagRoundIcon,
  GERMAN: GermanFlagRoundIcon,
  ITALIAN: ItalyFlagRoundIcon,
  SPANISH: SpanishFlagRoundIcon
}

export const subjectToFlagIcon: Record<Subject, typeof CzechFlagRoundIcon> = {
  ENGLISH: EnglishFlagIcon,
  CZECH: CzechFlagIcon,
  FRENCH: FranceFlagRoundIcon,
  GERMAN: GermanFlagRoundIcon,
  ITALIAN: ItalyFlagRoundIcon,
  SPANISH: SpanishFlagRoundIcon
}
