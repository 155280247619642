import classNames from 'classnames'
import { forwardRef } from 'react'
import { Check, AlertCircle, XClose } from '@untitled-ui/icons-react'
import { Divider } from '../Divider'

type VariantType = 'positive' | 'warning' | 'negative' | 'record'

type Props = React.HTMLProps<HTMLInputElement> & {
  variant?: VariantType
  onClick?: () => void
  label?: string
  message: string
}

const variantToIcon: Record<VariantType, React.ReactNode> = {
  positive: <Check className="text-positive" />,
  warning: <AlertCircle className="text-warningBase" />,
  negative: <AlertCircle className="text-negative" />,
  record: (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
      <circle cx="4" cy="4" r="4" fill="#F7483B" />
    </svg>
  )
}

/**
 * @group Components
 * @category Component
 */
export const SnackBar = forwardRef(
  (
    { className, variant = 'positive', label, onClick, message, ...restProps }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const icon = variantToIcon[variant]

    return (
      <div
        className={classNames(
          'flex flex-row gap-3 bg-black h-11 px-3 py-[10px] rounded-lg shadow-xs items-center',
          className
        )}
        ref={ref}
        {...restProps}
      >
        {icon}
        <p className="text-white">{message}</p>
        {label ? (
          <>
            <Divider direction="vertical" className="bg-grayNeutral-600" />
            <button onClick={onClick} className="text-white">
              {label}
            </button>
          </>
        ) : (
          <XClose onClick={onClick} className="text-grayNeutral-500 hover:cursor-pointer" />
        )}
      </div>
    )
  }
)
