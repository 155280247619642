import classnames from 'classnames'
import { Typography, TypographyProps, WeightTextType } from '.'

export type LabelSizeType = 'xl' | 'l' | 'm' | 's' | 'xs'

const sizeToStyle: Record<LabelSizeType, React.ComponentProps<'p'>['className']> = {
  xl: 'text-base tracking-[-0.36px]',
  l: 'text-sm',
  m: 'text-[13px] leading-4',
  s: 'text-xs',
  xs: 'text-[11px] leading-4'
}

const weightToStyle: Record<WeightTextType, React.ComponentProps<'p'>['className']> = {
  bold: 'font-semibold',
  semibold: 'font-medium',
  medium: 'font-normal',
  regular: 'font-light'
}

export type LabelProps = Omit<TypographyProps, 'variant'> & {
  size?: LabelSizeType
  label?: string
}

/**
 * @group Components
 * @category Atoms
 */
export const Label = ({
  className,
  weight = 'regular',
  size = 'm',
  Component = 'p',
  children,
  label,
  ...props
}: LabelProps) => {
  const sizeStyle = sizeToStyle[size]
  const weightStyle = weightToStyle[weight]

  return (
    <Typography
      className={classnames('text-light-black', sizeStyle, weightStyle, className)}
      weight={weight}
      {...props}
    >
      {label || children}
    </Typography>
  )
}
